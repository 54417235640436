import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, SocialDay } from 'types';
const SocialDayService = {
  updateSocialDays: async (eventId: string, periodId: string, socialDays: number) => {
    return axios.post<ResponseData<string>>(
      `${API_URL}events/${eventId}/periods/${periodId}/social-days`,
      {
        socialDays,
      }
    );
  },

  verifySocialDays: async (eventId: string, participantId: string) => {
    return axios.post<ResponseData<SocialDay>>(
      `${API_URL}events/${eventId}/participants/${participantId}/verify`
    );
  },

  getOneSocialDays: async (eventId: string, participantId: string) => {
    return axios.get<ResponseData<{ totalSocialDays: number }>>(
      `${API_URL}events/${eventId}/participants/${participantId}/social-days`
    );
  },

  getParticipantSocialDayStatus: async (eventId: string) => {
    return axios.get<ResponseData<SocialDay[]>>(`${API_URL}events/${eventId}/social-days`);
  },
};

export default SocialDayService;
