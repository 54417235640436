import * as fabric from 'fabric';
const imageFetcher = async (
  url: string,
  imageOptions: fabric.TOptions<fabric.ImageProps>
): Promise<fabric.FabricImage> => {
  const image = await fabric.FabricImage.fromURL(url);
  image.set(imageOptions);
  return image;
};

export default imageFetcher;
