import { createColumnHelper } from '@tanstack/react-table';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Badge, Card, Col, Container, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import Breadcrumb from 'Components/Common/Breadcrumb';
import DeleteModal from 'Components/Common/DeleteModal';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import TableContainer from 'Components/Common/TableContainer';
// import formatDate from 'helpers/formatDate';
import { handleAxiosError } from 'helpers/handleError';
import { secondsToDate } from 'helpers/timeConverter';
import { toStartCase } from 'helpers/translate';
import EventCoreService from 'services/event-core.service';
import { Event, EventPermissions, EventStatus } from 'types/core';

import { ReactComponent as Archive } from '../../assets/svg/archive.svg';
import { ReactComponent as Delete } from '../../assets/svg/delete.svg';
import { ReactComponent as ModeEdit } from '../../assets/svg/mode_edit.svg';
import { ReactComponent as TrendingUp } from '../../assets/svg/trending_up.svg';
import { ReactComponent as Restore } from '../../assets/svg/unarchive.svg';
import { ReactComponent as Visibility } from '../../assets/svg/visibility.svg';
import ParticipantService from '../../services/participant.service';

import ArchiveModal from './modal/ArchiveModal';
import RestoreModal from './modal/RestoreModal';

// import VerificationRequestCard from './VerificationRequestCard';

const limit = 10;

const EventList = () => {
  const [queryTitle, setQueryTitle] = useState('');
  const debounceTitle = useDebounceCallback(setQueryTitle, 500);
  const [queryOrder, setQueryOrder] = useState<'asc' | 'desc'>('desc');
  const [queryStatus, setQueryStatus] = useState<EventStatus | undefined>(undefined);
  const [eventList, setEventList] = useState<Event[]>([]);
  const [eventCount, setEventCount] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string>(''); // Initialize with null
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false);
  const [isRestoreModalVisible, setIsRestoreModalVisible] = useState(false);
  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => setIsDeleteModalVisible(false);
  const openArchiveModal = () => setIsArchiveModalVisible(true);
  const closeArchiveModal = () => setIsArchiveModalVisible(false);
  const openRestoreModal = () => setIsRestoreModalVisible(true);
  const closeRestoreModal = () => setIsRestoreModalVisible(false);

  const columnHelper = createColumnHelper<Event>();

  const navigate = useNavigate();

  const searchEventTitle = () => {
    const input = document.getElementById('searchbarEventTitle') as HTMLInputElement;
    debounceTitle(input.value);
  };

  const getEventList = useCallback(async () => {
    try {
      setLoading(true);
      const offset = (currentPage - 1) * limit;
      console.log(queryStatus);
      const { data } = await EventCoreService.getParticipatedEvents(
        queryOrder,
        limit,
        offset,
        queryTitle,
        queryStatus
      );
      const { payload } = data;
      if (queryStatus !== EventStatus.ARCHIVED) {
        const filteredEvents = payload.eventList.filter(
          (event) => event.status !== EventStatus.DELETED && event.status !== EventStatus.ARCHIVED
        );
        setEventList(filteredEvents);
        setEventCount(filteredEvents.length);
      } else {
        const filteredEvents = payload.eventList.filter(
          (event) => event.status === EventStatus.ARCHIVED
        );
        setEventList(filteredEvents);
        setEventCount(filteredEvents.length);
      }
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [currentPage, queryStatus, queryOrder, queryTitle]);

  useEffect(() => {
    getEventList();
  }, [getEventList]);

  const handleDelete = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.DELETE_EVENT)
    ) {
      try {
        await EventCoreService.deleteEvent(eventId);
        toast.success('Event is deleted successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeDeleteModal();
      }
    } else {
      toast.error('You do not have permission to delete this event.');
      closeDeleteModal();
    }
  };

  const handleArchive = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.DELETE_EVENT)
    ) {
      try {
        await EventCoreService.updateEventStatus(eventId, EventStatus.ARCHIVED as EventStatus);
        toast.success('Event is archived successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeArchiveModal();
      }
    } else {
      toast.error('You do not have permission to delete this event.');
      closeArchiveModal();
    }
  };
  const handleRestore = async (eventId: string) => {
    const updatedPermissions = await ParticipantService.getMyPermissions(eventId);
    if (
      updatedPermissions.data.payload.includes(EventPermissions.LEADER) ||
      updatedPermissions.data.payload.includes(EventPermissions.DELETE_EVENT)
    ) {
      try {
        await EventCoreService.updateEventStatus(eventId, EventStatus.ON_EVENT);
        toast.success('Event is restored successfully');
        getEventList();
      } catch (error) {
        handleAxiosError(error, (message) => toast.error(message));
      } finally {
        closeRestoreModal();
      }
    } else {
      toast.error('You do not have permission to delete this event.');
      closeRestoreModal();
    }
  };
  const isArchivedStatus = eventList.every((event) => event.status === EventStatus.ARCHIVED);
  const columns = [
    columnHelper.accessor('_id', {
      header: '#',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div>{cellProps.row.index + 1}</div>;
      },
      footer: (props) => props.column.id,
    }),

    columnHelper.accessor('title', {
      header: 'Name',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div className='tw-max-w-[13rem] tw-truncate'>{cellProps.getValue()}</div>;
      },
      footer: (props) => props.column.id,
    }),

    // columnHelper.accessor('description', {
    //   header: 'Description',
    //   enableColumnFilter: false,
    //   enableGlobalFilter: false,
    //   cell: (cellProps) => {
    //     return <div className='tw-max-w-[24rem] tw-truncate'>{cellProps.getValue()}</div>;
    //   },
    //   footer: (props) => props.column.id,
    // }),
    columnHelper.accessor('createdBy.name', {
      header: 'Creator',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div className='tw-max-w-[10.5rem] tw-truncate'>{cellProps.getValue()}</div>;
      },
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor('createdAt', {
      header: 'Created at',
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: (cellProps) => {
        return <div>{secondsToDate(cellProps.getValue())}</div>;
      },
      footer: (props) => props.column.id,
    }),

    ...(!isArchivedStatus
      ? [
          columnHelper.accessor('status', {
            header: 'Phase',
            enableColumnFilter: false,
            enableGlobalFilter: false,
            cell: (cellProps) => {
              const status = toStartCase(cellProps.getValue());
              return (
                <Badge
                  color='primary'
                  style={{
                    borderRadius: '0.5rem',
                    paddingTop: '0.25rem',
                    paddingBottom: '0.25rem',
                    paddingLeft: '0.5rem',
                    paddingRight: '0.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}
                >
                  {status}
                </Badge>
              );
            },
            footer: (props) => props.column.id,
          }),
        ]
      : []),

    columnHelper.accessor('_id', {
      header: () => {
        return <div className='tw-w-full tw-flex tw-flex-row tw-justify-end'>Actions</div>;
      },
      enableColumnFilter: false,
      enableGlobalFilter: false,
      enableSorting: false,
      cell: (cellProps) => {
        return (
          <div className='tw-flex tw-flex-row tw-gap-[0.5rem] tw-justify-end'>
            <button
              onClick={() => navigate(`/event/${cellProps.getValue()}`)}
              className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              id='detailEventTooltip'
            >
              <ModeEdit width={16} height={16} fill='#3D4863' />
              <UncontrolledTooltip placement='top' target='detailEventTooltip'>
                Detail
              </UncontrolledTooltip>
            </button>

            <button
              onClick={() => navigate(`/event/management/${cellProps.getValue()}`)}
              className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              id='manageEventTooltip'
            >
              <Visibility width={16} height={16} fill='#3D4863' />
              <UncontrolledTooltip placement='top' target='manageEventTooltip'>
                Management
              </UncontrolledTooltip>
            </button>

            <button
              onClick={() => navigate(`/event/observations/${cellProps.getValue()}`)}
              className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              id='reportEventTooltip'
            >
              <TrendingUp width={16} height={16} fill='#3D4863' />
              <UncontrolledTooltip placement='top' target='reportEventTooltip'>
                Statistics report
              </UncontrolledTooltip>
            </button>

            {!isArchivedStatus && (
              <button
                onClick={() => {
                  setSelectedEventId(cellProps.getValue());
                  openArchiveModal();
                }}
                className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
              >
                <Archive width={16} height={16} fill='#3D4863' id='archiveEventTooltip' />
                {isArchiveModalVisible && (
                  <ArchiveModal
                    show={isArchiveModalVisible}
                    title='Archive Event'
                    content='Are you sure you want to archive this event?'
                    disabled={loading}
                    onDeleteClick={() => {
                      handleArchive(selectedEventId);
                    }}
                    onCloseClick={closeArchiveModal}
                  />
                )}
                <UncontrolledTooltip placement='top' target='archiveEventTooltip'>
                  Archive
                </UncontrolledTooltip>
              </button>
            )}

            {isArchivedStatus && (
              <button
                onClick={() => {
                  setSelectedEventId(cellProps.getValue());
                  openRestoreModal();
                }}
                className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
                id='deleteRestoreTooltip'
              >
                <Restore width={16} height={16} fill='#3D4863' />
                {isRestoreModalVisible && (
                  <RestoreModal
                    show={isRestoreModalVisible}
                    title='Restore Event'
                    content='Are you sure you want to restore this event?'
                    disabled={loading}
                    onDeleteClick={() => {
                      handleRestore(selectedEventId);
                    }}
                    onCloseClick={closeRestoreModal}
                  />
                )}
                <UncontrolledTooltip placement='top' target='deleteRestoreTooltip'>
                  Restore
                </UncontrolledTooltip>
              </button>
            )}

            {isArchivedStatus && (
              <button
                onClick={() => {
                  setSelectedEventId(cellProps.getValue());
                  openDeleteModal();
                }}
                className='tw-rounded-[0.5rem] tw-bg-white tw-w-[2rem] tw-h-[2rem] tw-border tw-border-[#3D4863] tw-flex tw-flex-col tw-justify-center tw-items-center'
                id='deleteEventTooltip'
              >
                <Delete width={16} height={16} fill='#3D4863' />
                {isDeleteModalVisible && (
                  <DeleteModal
                    show={isDeleteModalVisible}
                    title='Delete Event'
                    content="This action is very dangerous and can't be undone!"
                    disabled={loading}
                    onDeleteClick={() => {
                      handleDelete(selectedEventId);
                    }}
                    onCloseClick={closeDeleteModal}
                  />
                )}
                <UncontrolledTooltip placement='top' target='deleteEventTooltip'>
                  Delete
                </UncontrolledTooltip>
              </button>
            )}
          </div>
        );
      },
      footer: (props) => props.column.id,
    }),
  ];

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='All event' />
          <Row>
            <Col xs={12}>
              <Card>
                <div
                  className='tw-p-5 tw-p-t-[1.5rem] md:tw-p-[2.5rem] md:tw-p-t-[1.75rem] tw-rounded-[0.25rem]'
                  // style={{
                  //   padding: '2.5rem',
                  //   paddingTop: '1.75rem',
                  // }}
                >
                  <div className='tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-mb-3 tw-gap-4'>
                    <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                      <i className='bx bx-search-alt' />
                      <Input
                        className='!tw-border-0'
                        onKeyUp={searchEventTitle}
                        id='searchbarEventTitle'
                        type='text'
                        placeholder='Search...'
                      />
                    </div>

                    {/* filter */}
                    <div className='tw-flex tw-flex-row tw-w-full sm:tw-w-fit tw-justify-between xs:tw-justify-start tw-h-[2.5rem] tw-gap-4'>
                      <Input
                        id='orderSelect'
                        className='form-control !tw-w-full xs:!tw-w-[8rem]'
                        name='orderSelect'
                        defaultValue='asc'
                        onChange={(e) => {
                          const order = e.target.value;
                          if (order === 'asc') setQueryOrder('asc');
                          else setQueryOrder('desc');
                        }}
                        type='select'
                        placeholder='Order'
                      >
                        <option key='asc' value='asc'>
                          Ascending
                        </option>
                        <option key='desc' value='desc'>
                          Descending
                        </option>
                      </Input>
                      <Input
                        id='statusSelect'
                        className='form-control !tw-w-full sm:!tw-w-[12.5rem]'
                        name='statusSelect'
                        defaultValue={undefined}
                        onChange={(e) => {
                          const status = e.target.value;
                          if (status === 'all') setQueryStatus(undefined);
                          else setQueryStatus(status as EventStatus);
                        }}
                        type='select'
                        placeholder='Status'
                      >
                        <option value={'all'}>All phases</option>
                        {Object.values(EventStatus)
                          .filter((status) => status !== EventStatus.DELETED)
                          .map((value) => (
                            <option key={value} value={value}>
                              {toStartCase(value)}
                            </option>
                          ))}
                      </Input>
                    </div>
                  </div>

                  {/* event verification section */}
                  {/* <div className='tw-p-[1rem] tw-w-full tw-overflow-x-scroll tw-bg-primary tw-rounded-[0.5rem] tw-flex tw-flex-col tw-gap-y-4 tw-mb-4'>
                    <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-h-fit'>
                      <div className='tw-text-white tw-text-[0.875rem] sm:tw-text-base tw-h-fit tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <div className='tw-rounded-2xl tw-bg-white tw-w-3 tw-h-3'></div>
                        <span className='tw-hidden md:tw-block'>
                          3 new events need your verification
                        </span>
                        <span className='tw-block md:tw-hidden'>3 new events</span>
                      </div>
                    </div>

                    <div className='tw-flex tw-w-fit tw-flex-row tw-gap-x-4 tw-overflow-x-hidden scrollbar-transparent'>
                      {eventList.map((event) => (
                        <VerificationRequestCard key={event._id} event={event} />
                      ))}
                    </div>
                  </div> */}

                  <Row className='mb-2'>
                    {!loading && (
                      <TableContainer
                        columns={columns}
                        tableClass='table align-middle table-nowrap'
                        theadClass='tw-bg-[#3D4863] tw-rounded-t-lg tw-text-white tw-font-bold'
                        data={eventList}
                        pageSize={limit}
                      />
                    )}
                    {loading && <Loading width={360} height={360} />}
                  </Row>
                  <div className='tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10'>
                    <Pagination
                      count={eventCount}
                      pageSize={limit}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EventList;
