import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody } from 'reactstrap';
import { utils, writeFile } from 'xlsx';

import { Participant } from 'types';

import { secondsToTimeDate } from '../../../helpers/timeConverter';
type ExportReportModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  title: string;
  getRole: (roleId: string) => string;
  onCloseClick: () => void;
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  fetchAllParticipants: () => Promise<Participant[] | undefined>;
  fetchAllSocialDaysStatus: () => Promise<
    | Array<{
        name: string;
        email: string;
        totalSocialDays: number;
        verifiedStatus: boolean;
      }>
    | undefined
  >;
};

export const ExportReportModal = ({
  show,
  setShow,
  title,
  getRole,
  isFetching,
  setIsFetching,
  onCloseClick,
  fetchAllParticipants,
  fetchAllSocialDaysStatus,
}: ExportReportModalProps) => {
  const downloadSheetTemplate = useCallback(
    (
      participantList: Array<Participant> | undefined,
      participantSocialDaysStatusList:
        | Array<{
            name: string;
            email: string;
            totalSocialDays: number;
            verifiedStatus: boolean;
          }>
        | undefined
    ) => {
      if (!participantList) {
        toast.error(
          'Failed to fetch full participation report for this event, please try again later'
        );
        return;
      } else if (participantList.length === 0) {
        toast.error('No data to export');
        return;
      }
      const rows = participantList.map((participant) => {
        const totalAttendedPeriods = participant.attendedPeriods.filter(
          (period) => period.attendedAt !== undefined
        ).length;
        const totalSocialDays =
          participantSocialDaysStatusList?.find(
            (socialDays) => socialDays?.email === participant.user.email
          )?.totalSocialDays || 0; // Default to 0 if not found

        const rowData = {
          name: participant.user.name,
          email: participant.user.email,
          role: getRole(participant.role),
          totalAttendedPeriods: totalAttendedPeriods,
          totalSocialdays: totalSocialDays,
        };
        participant.attendedPeriods.forEach(
          (period) => (rowData[period.period] = secondsToTimeDate(period.attendedAt))
        );

        return rowData;
      });
      const worksheet = utils.json_to_sheet(rows);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, `${title} Report`);

      const headers = ['Name', 'Email', 'Role', 'Total Attended Periods', 'Total Social Days'];

      participantList[0].attendedPeriods.forEach((period) => {
        headers.push(period.period);
      });
      utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      worksheet['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];

      participantList[0].attendedPeriods.forEach(() => {
        if (worksheet['!cols']) worksheet['!cols'].push({ wch: 20 });
      });

      writeFile(workbook, `${title} Report.xlsx`, { compression: true });
    },
    [getRole, title]
  );

  const onDownloadClick = useCallback(async () => {
    setIsFetching(true);
    try {
      const participantsInfo = await fetchAllParticipants();
      const participantSocialDaysStatus = await fetchAllSocialDaysStatus();
      downloadSheetTemplate(participantsInfo, participantSocialDaysStatus);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
      setShow(false);
    }
  }, [
    downloadSheetTemplate,
    fetchAllParticipants,
    setIsFetching,
    setShow,
    fetchAllSocialDaysStatus,
  ]);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='md'>
      <div className='modal-header'>
        <h5 className='modal-title mt-0'>Download report</h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content '>
        <ModalBody className='px-4 py-4'>
          <div>Download a full participation report of this event?</div>
          <div className='text-end mt-3'>
            <Button
              type='button'
              color='primary'
              disabled={isFetching}
              onClick={() => {
                onDownloadClick();
              }}
            >
              Download
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
