import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { toast } from 'react-toastify';
import { Button, Input, Modal, ModalBody, TabContent, TabPane } from 'reactstrap';

import { EventPeriod } from 'types';

import { ReactComponent as Done } from '../../../assets/svg/done.svg';

type ScanModalProps = {
  eventPeriods: EventPeriod[];
  show: boolean;
  onCloseClick: () => void;
  scanningPhase: string;
  setScanningPhase: (newData: 'Scanning' | 'Processing' | 'Done') => void;
  registerAttendance: (periodId: string, data: string) => Promise<void>;
};

const ScanModal = ({
  eventPeriods,
  show,
  onCloseClick,
  scanningPhase,
  setScanningPhase,
  registerAttendance,
}: ScanModalProps) => {
  const [periodId, setPeriodId] = useState<string>('No period');

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='sm'>
      <div className='modal-content '>
        <ModalBody className='tw-p-2 tw-flex tw-flex-col tw-items-center tw-gap-4'>
          <TabContent>
            <TabPane>
              {eventPeriods.length > 0 ? (
                <div className='tw-flex tw-flex-col tw-items-center tw-gap-4'>
                  <div className='tw-font-bold tw-text-[1.5rem]'>Attendance registration</div>
                  <Input
                    id='type'
                    name='type'
                    type='select'
                    className='tw-w-[8rem] tw-h-[2.5rem] tw-flex tw-items-center tw-relative'
                    placeholder='Enter period type...'
                    onChange={(e) => {
                      console.log(e.target.value);
                      setPeriodId(e.target.value);
                    }}
                  >
                    <option value={'No period'}>No period</option>
                    {eventPeriods.map((period) => {
                      return (
                        <option key={period._id} value={period._id}>
                          {period.title}
                        </option>
                      );
                    })}
                  </Input>
                  <div id='qrScanElement' className='tw-relative tw-w-[15rem] tw-flex tw-flex-col'>
                    <QrReader
                      constraints={{ aspectRatio: 1, width: 160 }}
                      onResult={(result, error) => {
                        setScanningPhase('Processing');
                        if (!!result) {
                          if (periodId === 'No period') {
                            toast.error('Please choose a period first!');
                            return;
                          }
                          registerAttendance(periodId, result?.getText());
                        }

                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      className='tw-relative tw-w-[15rem] tw-aspect-square tw-border-8 tw-border-[#3D4863] tw-border-solid tw-rounded-lg'
                    />
                    {scanningPhase === 'Done' && (
                      <div className='tw-absolute tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-w-full tw-h-full tw-bg-[#3D4863CC] tw-rounded-lg'>
                        <div className='tw-relative tw-bg-[#0F9D58] tw-rounded-full tw-w-[4rem] tw-h-[4rem] tw-flex tw-items-center tw-justify-center'>
                          <Done className='tw-w-[2.5rem] tw-h-[2.5rem] tw-relative' />
                        </div>
                        <div className='tw-font-bold tw-text-white'>Registrated</div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`${
                      scanningPhase === 'Done'
                        ? 'tw-text-[#0F9D58]'
                        : scanningPhase === 'Done'
                        ? 'tw-text-[#F1B44C]'
                        : ''
                    } tw-font-bold`}
                  >
                    {scanningPhase}
                  </div>
                </div>
              ) : (
                <div
                  id='qrScanElement'
                  className='tw-relative tw-w-[15rem] tw-flex tw-flex-col tw-text-center'
                >
                  There is no period to register!
                </div>
              )}
            </TabPane>
          </TabContent>
          <Button type='button' color='primary' disabled={false} onClick={onCloseClick}>
            Close
          </Button>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ScanModal;
