import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, Participant, EventPermissions } from 'types';

const ParticipantService = {
  addParticipant: async (
    eventId: string,
    information: {
      roleId: string;
      userId: string;
    }
  ) => {
    return axios.post<ResponseData<Participant>>(
      `${API_URL}events/${eventId}/participants`,
      information
    );
  },

  getParticipantsOfEvent: async (
    eventId: string,
    order: 'asc' | 'desc',
    limit: number,
    offset: number,
    name?: string,
    email?: string,
    attended?: boolean
  ) => {
    return axios.get<ResponseData<{ participantList: Array<Participant>; count: number }>>(
      `${API_URL}events/${eventId}/participants?sortBy=createdAt&order=${order}&limit=${limit}&offset=${offset}${
        name ? `&name=${name}` : ''
      }${email ? `&email=${email}` : ''}${attended ? '&attended=true' : ''}`
    );
  },

  getMyEventById: async (eventId: string) => {
    return axios.get<ResponseData<Participant>>(`${API_URL}events/${eventId}/participant`);
  },

  getParticipantById: async (eventId: string, participantId: string) => {
    return axios.get<ResponseData<Participant>>(
      `${API_URL}events/${eventId}/participants/${participantId}`
    );
  },

  getParticipantQrsOfEvent: async (eventId: string) => {
    return axios.get<ResponseData<Array<string>>>(`${API_URL}events/${eventId}/qr`);
  },

  downloadQrsOfEvent: async (
    eventId: string,

    selectedParticipantIds?: Array<string>,
    exclusionMode?: boolean
  ) => {
    let selectedParticipantQuery = '';
    if (selectedParticipantIds && selectedParticipantIds.length > 0)
      selectedParticipantQuery = selectedParticipantIds
        .map((participantId) => 'participantIds=' + participantId)
        .join('&');

    return axios.get(
      `${API_URL}events/${eventId}/qrs/download?${selectedParticipantQuery}&exclusionMode=${
        exclusionMode ? 'true' : 'false'
      }`,
      {
        responseType: 'arraybuffer',
      }
    );
  },

  getParticipantQrById: async (eventId: string, participantId: string) => {
    return axios.get<ResponseData<string>>(`${API_URL}events/${eventId}/qr/${participantId}`);
  },

  deleteParticipant: async (eventId: string, participantId: string) => {
    return axios.delete<ResponseData<Participant>>(
      `${API_URL}events/${eventId}/participants/${participantId}`
    );
  },

  getMyPermissions: async (eventId: string) => {
    return axios.get<ResponseData<EventPermissions[]>>(
      `${API_URL}events/${eventId}/my-permissions`
    );
  },

  bulkInsertAndUpdate: async (
    eventId: string,
    insertList: {
      roleId: string;
      userId: string;
    }[],
    removeList: string[],
    exclusionMode: boolean
  ) => {
    return axios.post<ResponseData<Participant>>(`${API_URL}events/${eventId}/participants/bulk`, {
      insertList,
      removeList,
      exclusionMode,
    });
  },

  bulkRolesChange: async (
    eventId: string,
    participantList: {
      roleId: string;
      userId: string;
    }[],
    roleId: string,
    exclusionMode: boolean
  ) => {
    return axios.patch<
      ResponseData<{
        acknowledged: boolean;
        modifiedCount: number;
        upsertedId: string | null;
        upsertedCount: number;
        matchedCount: number;
      }>
    >(`${API_URL}events/${eventId}/participants/role-change`, {
      participantList,
      roleId,
      exclusionMode,
    });
  },

  bulkInsertByEmails: async (eventId: string, roleId: string, emails: string[]) => {
    return axios.post<ResponseData<{ inserted: number; removed: number }>>(
      `${API_URL}events/${eventId}/participants/insert/emails`,
      {
        emails,
        roleId,
      }
    );
  },
};

export default ParticipantService;
