import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { CertParameter, Certificate, CertificateTemplate, ResponseData } from 'types';

const CertificateTemplateService = {
  getCertificateTemplateList: async (limit: number, offset = 0) => {
    return axios.get<
      ResponseData<{
        count: number;
        certificateTemplateList: CertificateTemplate[];
      }>
    >(`${API_URL}admin/certificates/templates?limit=${limit}&offset=${offset}`);
  },
  getCertificateTemplateById: async (id: string) => {
    return axios.get<ResponseData<CertificateTemplate>>(
      `${API_URL}admin/certificates/templates/${id}`
    );
  },
  createCertificateTemplate: async (
    name: string,
    description: string,
    parameters: CertParameter[],
    file: File
  ) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('parameters', `${JSON.stringify(parameters)}`);
    formData.append('file', file);

    return axios.post<ResponseData<CertificateTemplate>>(
      `${API_URL}admin/certificates/templates`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  editCertificateTemplate: async (id: string, name: string, description: string) => {
    return axios.patch<ResponseData<Certificate>>(`${API_URL}admin/certificates/templates/${id}`, {
      name,
      description,
    });
  },
};

export default CertificateTemplateService;
