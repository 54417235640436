import * as fabric from 'fabric';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import getRandomQuote from 'common/quotes';
import Loading from 'Components/Common/LoadingIndicator';
import { handleAxiosError } from 'helpers/handleError';
import useTitle from 'hooks/useTitle';
import CertificateService from 'services/certificate.service';
import { Certificate, CertificateTemplate } from 'types';

import { ReactComponent as DQuotes } from '../../assets/svg/dquotes.svg';

import { CertificateContainer } from './CertificateContainer';

const initialTemplate: CertificateTemplate = {
  _id: '',
  name: '',
  description: '',
  parameters: [],
  background: {
    _id: '',
    originalName: '',
    filename: '',
    directoryId: '',
    refName: '',
    size: 0,
    mimetype: '',
    createdAt: 0,
    updatedAt: 0,
  },
  backgroundUrl: '',
  thumbnail: '',
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};
const initialCertificate: Certificate = {
  _id: '',
  certificateId: '',
  recipient: '',
  batch: {
    _id: '',
    name: '',
    batchId: '',
    description: '',
    template: initialTemplate,
    createdBy: {
      _id: '',
      name: '',
      googleId: '',
      picture: '',
      email: '',
      permissions: [],
      isManager: false,
    },
    signee: '',
    signeePosition: '',
    createdAt: 0,
    updatedAt: 0,
    deletedAt: 0,
  },
  template: initialTemplate,
  thumbnail: '',
  parameters: [],
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};

const PublicUserCertificate = () => {
  useTitle('Certificate', {
    restoreOnUnmount: true,
  });

  const { certificateId } = useParams();
  const [certificate, setCertificate] = useState<Certificate>(initialCertificate);

  const [loading, setLoading] = useState(true);

  const cerficateTemplate = certificate.template;

  const quote = getRandomQuote();

  const getFileFromCanvas = useCallback((canvas: fabric.Canvas) => {
    if (!canvas) return Promise.reject('Canvas is not found');
    return new Promise<File>((resolve, reject) => {
      canvas.getElement().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'thumbnail.jpeg', { type: 'image/jpeg' });
          resolve(file);
        } else {
          reject('Error while getting file from canvas');
        }
      });
    });
  }, []);

  const generateCertificateThumbnail = useCallback(
    async (_id: string, canvas: fabric.Canvas) => {
      console.log('generate thumbnail for ', _id);
      const file: File = await getFileFromCanvas(canvas);
      try {
        await CertificateService.generateThumbnail(_id || '', file);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      }
    },
    [getFileFromCanvas]
  );

  const onCertificateLoad = useCallback(
    (canvas: fabric.Canvas) => {
      _.debounce(() => {
        if (certificate && !certificate.thumbnail && canvas)
          generateCertificateThumbnail(certificate._id, canvas).catch(() => {});
      }, 500)();
    },
    [generateCertificateThumbnail, certificate]
  );

  const fetchCertificate = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await CertificateService.getUserCertificateById(certificateId || '');
      const { payload } = data;
      setCertificate(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [certificateId]);

  useEffect(() => {
    fetchCertificate();
  }, [fetchCertificate]);

  if (loading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <div className='tw-w-screen tw-h-full tw-relative tw-left-0 tw-flex tw-flex-col tw-justify-between tw-text-primary tw-overflow-hidden'>
      <div className='tw-ml-[0.5rem] lg:tw-ml-[2.5rem] tw-text-[8rem] md:tw-text-[9rem] lg:tw-text-[10rem] xl:tw-text-[12rem] 2xl:tw-text-[12.5rem] lg:tw-mt-2 tw-h-[10rem] tw-flex tw-items-center tw-font-[800] tw-absolute tw-text-[#0b28780d] tw-animate-slideEntrance'>
        CERTIFICATE
      </div>
      <div className='tw-w-full tw-flex tw-flex-col lg:tw-flex-row tw-mt-[6.5rem] xs:tw-mt-[5rem] lg:tw-px-[5rem] xl:tw-pl-[7.5rem] xl:tw-pr-[5rem] lg:tw-justify-between'>
        <div className='tw-w-full lg:tw-w-[35rem] tw-relative tw-flex'>
          <CertificateContainer
            canvasId='publicCanvas'
            templateBackground={cerficateTemplate.backgroundUrl}
            certificateTemplate={cerficateTemplate}
            displayCertificate={certificate}
            onLoad={onCertificateLoad}
            editMode={false}
            buttonOptions={{
              copyButton: false,
              viewButton: false,
              downloadButton: false,
            }}
            tooltips={true}
          />
        </div>
        <div className='tw-relative tw-flex tw-flex-col tw-items-center tw-mt-4 lg:tw-w-[22.5rem] lg:tw-mt-0 xl:tw-w-[30rem] 2xl:tw-w-[40rem] tw-px-[2.5rem] lg:tw-pb-8 tw-justify-between'>
          <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-relative'>
            <div className='tw-text-[2rem] lg:tw-text-[2.5rem] xl:tw-text-[3rem] tw-font-bold tw-animate-appearance tw-opacity-0 tw-truncate tw-max-w-[20rem] md:tw-max-w-[40rem] lg:tw-max-w-[20rem] 2xl:tw-max-w-[32.5rem]'>
              {certificate.batch.name}
            </div>
            <div className='tw-pt-[0.5rem] tw-mt-[0.5rem] tw-border-t-[1px] tw-border-primary tw-animate-appearance tw-opacity-0 tw-overflow-y-scroll no-scrollbar tw-w-full tw-max-h-[12rem] lg:tw-max-h-[10rem]'>
              {certificate.batch.description}
            </div>
          </div>
          <div className='tw-pt-[0.5rem] tw-mt-[0.5rem] tw-animate-appearance tw-opacity-0'>
            <div className='tw-grid tw-grid-cols-2'>
              <div className='tw-font-bold'>Certificate ID:</div>
              <div>{certificate.certificateId}</div>
              <div className='tw-font-bold'>Date:</div>
              <div>{new Date(certificate.batch.createdAt).toLocaleString()}</div>
            </div>
          </div>
          <div className='xl:tw-flex tw-w-full tw-hidden tw-flex-col tw-justify-center tw-items-center tw-relative tw-text-[0.75rem] tw-italic tw-gap-[0.5rem] tw-animate-appearance tw-opacity-0'>
            <DQuotes width={16} height={16} fill='#0B2878' />
            <div className='tw-mt-2 tw-text-center'>"{quote.content}"</div>
            <div>- {quote.author} -</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicUserCertificate;
