import { API_URL } from 'config';
import { axios } from 'helpers/custom-axios';
import { ResponseData, EventPeriod, Participant } from 'types';

const EventPeriodService = {
  createAttendancePeriod: async (
    eventId: string,
    title: string,
    startAt: number,
    endAt: number,
    maxScanRate?: number | null
  ) => {
    return axios.post<ResponseData<EventPeriod>>(`${API_URL}events/${eventId}/attendance`, {
      title,
      startAt,
      endAt,
      maxScanRate,
    });
  },

  modifyPeriod: async (
    eventId: string,
    periodId: string,
    title: string,
    periodType: 'attendance' | 'registration',
    startAt: number,
    endAt: number,
    maxScanRate: number | null | undefined
  ) => {
    return axios.patch<ResponseData<EventPeriod>>(
      `${API_URL}events/${eventId}/periods/${periodId}`,
      {
        title,
        periodType,
        startAt,
        endAt,
        maxScanRate,
      }
    );
  },

  deletePeriod: async (eventId: string, periodId: string) => {
    return axios.delete<ResponseData<EventPeriod>>(
      `${API_URL}events/${eventId}/periods/${periodId}`
    );
  },

  getEventTimeline: async (eventId: string) => {
    return axios.get<ResponseData<EventPeriod[]>>(`${API_URL}events/${eventId}/timeline`);
  },

  getActivePeriods: async (eventId: string) => {
    return axios.get<ResponseData<EventPeriod[]>>(`${API_URL}events/${eventId}/active-periods`);
  },

  registerAttendance: async (eventId: string, periodId: string, participantId: string) => {
    return axios.post<ResponseData<Participant>>(
      `${API_URL}events/${eventId}/periods/${periodId}/attendance/${participantId}`
    );
  },
};

export default EventPeriodService;
