import * as fabric from 'fabric';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import { handleAxiosError } from 'helpers/handleError';
// import timeSince from 'helpers/time-since';
import timeSince from 'helpers/time-since';
import useTitle from 'hooks/useTitle';
import CertificateService from 'services/certificate.service';
import { Certificate, CertificateTemplate } from 'types';

// import temp1 from '../../../assets/images/certificateTemplates/temp1.jpg';
import { CertificateContainer } from '../CertificateContainer';

// import CertificateEditspace from './CertificateEditspace';

const initialTemplate: CertificateTemplate = {
  _id: '',
  name: '',
  description: '',
  parameters: [],
  background: {
    _id: '',
    originalName: '',
    filename: '',
    directoryId: '',
    refName: '',
    size: 0,
    mimetype: '',
    createdAt: 0,
    updatedAt: 0,
  },
  backgroundUrl: '',
  thumbnail: '',
  createdBy: {
    _id: '',
    name: '',
    googleId: '',
    picture: '',
    email: '',
    permissions: [],
    isManager: false,
  },
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};
const initialCertificate: Certificate = {
  _id: '',
  certificateId: '',
  recipient: '',
  batch: {
    _id: '',
    name: '',
    batchId: '',
    description: '',
    template: initialTemplate,
    createdBy: {
      _id: '',
      name: '',
      googleId: '',
      picture: '',
      email: '',
      permissions: [],
      isManager: false,
    },
    signee: '',
    signeePosition: '',
    createdAt: 0,
    updatedAt: 0,
    deletedAt: 0,
  },
  template: initialTemplate,
  thumbnail: '',
  parameters: [],
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
};

const DetailUserCertificate = () => {
  useTitle('Certificate detail', {
    restoreOnUnmount: true,
  });

  // const navigate = useNavigate();

  const { id } = useParams();
  const [certificate, setCertificate] = useState<Certificate>(initialCertificate);
  const [isLoading, setIsLoading] = useState(true);
  const cerficateTemplate = certificate.template;

  const getFileFromCanvas = useCallback((canvas: fabric.Canvas) => {
    if (!canvas) return Promise.reject('Canvas is not found');
    return new Promise<File>((resolve, reject) => {
      canvas.getElement().toBlob((blob) => {
        if (blob) {
          const file = new File([blob], 'thumbnail.jpeg', { type: 'image/jpeg' });
          resolve(file);
        } else {
          reject('Error while getting file from canvas');
        }
      });
    });
  }, []);

  const generateCertificateThumbnail = useCallback(
    async (_id: string, canvas: fabric.Canvas) => {
      console.log('generate thumbnail for ', _id);
      const file: File = await getFileFromCanvas(canvas);
      try {
        await CertificateService.generateThumbnail(_id || '', file);
      } catch (error: unknown) {
        handleAxiosError(error, (message) => toast.error(message));
      }
    },
    [getFileFromCanvas]
  );

  const onCertificateLoad = useCallback(
    (canvas: fabric.Canvas) => {
      _.debounce(() => {
        // Generate thumbnail for certificate if it doesn't have one
        if (certificate && !certificate.thumbnail && canvas)
          generateCertificateThumbnail(certificate._id, canvas).catch(() => {});
      }, 500)();
    },
    [generateCertificateThumbnail, certificate]
  );

  const fetchCertificate = useCallback(async () => {
    try {
      const { data } = await CertificateService.getUserCertificateById(id || '');
      const { payload } = data;
      setCertificate(payload);
      setIsLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  useEffect(() => {
    fetchCertificate();
  }, [fetchCertificate]);

  if (isLoading) {
    return <Loading width={600} height={600} />;
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title='Certificate'
            breadcrumbItem='Certificate detail'
            backTo='/user/certificate'
          />

          <Row>
            <Col>
              <Card>
                <CardBody
                  style={{
                    position: 'relative',
                    display: 'flex',
                    background: '#0b2878',
                    borderRadius: '0.25rem',
                  }}
                >
                  <div className='waveSec wave3'></div>
                  <div className='waveSec wave4'></div>
                  <div className='cert-dtlCol'>
                    <CertificateContainer
                      canvasId='detailCertificateCanvas'
                      templateBackground={cerficateTemplate.backgroundUrl}
                      certificateTemplate={cerficateTemplate}
                      displayCertificate={certificate}
                      onLoad={onCertificateLoad}
                      editMode={false}
                      buttonOptions={{
                        copyButton: true,
                        viewButton: true,
                        downloadButton: true,
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
              <Card className='cert-dtlCard'>
                <CardBody style={{ display: 'flex', flexDirection: 'column' }}>
                  <CardTitle>Detail</CardTitle>
                  <Row>
                    <Col xs={4} sm={3} lg={2} className='fw-semibold mb-3'>
                      ID
                    </Col>
                    <Col>
                      <p className='text-muted'>{certificate.certificateId}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} sm={3} lg={2} className='fw-semibold mb-3'>
                      Date
                    </Col>
                    <Col>
                      <p className='text-muted'>
                        {Date.now() - certificate.createdAt >= 846000000
                          ? new Date(certificate.createdAt).toLocaleString('vi-VN')
                          : timeSince(certificate.createdAt)}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4} sm={3} lg={2} className='fw-semibold mb-3'>
                      Signee
                    </Col>
                    <Col>
                      <p className='text-muted'>
                        {certificate.batch.signeePosition} {certificate.batch.signee}
                      </p>
                    </Col>
                  </Row>
                  {/* <div className='text-end mt-3'>
                    <Button
                      type='button'
                      color='primary'
                      outline
                      style={{ marginRight: '8px' }}
                      onClick={() => {
                        try {
                          navigator.clipboard.writeText(
                            'https://oisp.fessior.dev/chungnhan/' +
                              certificate.certificateId
                          );
                          toast.success('A link has been copied to clipboard');
                        } catch (error: unknown) {
                          handleAxiosError(error, (message) => toast.error(message));
                        }
                      }}
                    >
                      Copy to clipboard
                    </Button>
                    <Button
                      type='button'
                      color='primary'
                      onClick={() => {
                        navigate('/chungnhan/' + certificate.certificateId);
                      }}
                    >
                      View
                    </Button>
                  </div> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DetailUserCertificate;
