import * as fabric from 'fabric';
import {
  MutableRefObject,
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';

import { fonts, styles, weights, alignments } from 'common/certificateFonts';
import ignoredParams from 'common/ignoredParams';
import getAllParams from 'helpers/getAllParams';
import { handleAxiosError } from 'helpers/handleError';
import imageFetcher from 'helpers/imageFetcher';
import { CertParameter, Certificate, CertificateTemplate } from 'types';

import fakeQR from '../../assets/images/fakeQR.png';
import fakeSignature from '../../assets/images/fakeSignature.png';

type CanvasObjectProperty = {
  key: keyof fabric.Textbox;
  inputType: 'number' | 'text' | 'textarea' | 'select' | 'time' | 'color';
  selections?: Array<string>;
};

const canvasObjectProperties: Array<CanvasObjectProperty> = [
  { key: 'text', inputType: 'textarea' },
  { key: 'width', inputType: 'number' },
  { key: 'fontFamily', inputType: 'select', selections: fonts },
  { key: 'fontSize', inputType: 'number' },
  { key: 'fontStyle', inputType: 'select', selections: styles },
  { key: 'fontWeight', inputType: 'select', selections: weights },
  { key: 'textAlign', inputType: 'select', selections: alignments },
  { key: 'fill', inputType: 'color' },
];

type TooltipProps = {
  name: string;
  icon: string;
  content?: Array<string>;
  onClick?: () => void;
};

const Tooltip = ({ name, icon, content, onClick }: TooltipProps) => {
  return (
    <div
      onClick={onClick}
      className={`${
        onClick ? 'tw-cursor-pointer' : ''
      } tw-relative tw-w-[2.5rem] tw-h-[2.5rem] tw-bg-primary tw-rounded-lg tw-flex tw-flex-col tw-overflow-hidden hover:tw-w-[12.5rem] hover:tw-h-auto tw-text-white tw-p-2 tw-duration-200 tw-ease-in-out tw-z-40 tw-group`}
    >
      <div className='tw-relative tw-flex tw-flex-row tw-items-center tw-font-bold tw-h-[1.5rem] tw-gap-2'>
        <i className={`${icon} tw-relative  tw-text-[1.5rem]`}></i>
        <div className='tw-h-full tw-w-auto tw-hidden tw-delay-150 group-hover:tw-flex tw-items-center tw-overflow-hidden text-nowrap	'>
          {name}
        </div>
      </div>
      {content && (
        <div className='tw-hidden group-hover:tw-flex tw-relative tw-items-start tw-border-white tw-border-l-[1px] tw-border-solid tw-pl-2 tw-m-2 tw-max-h-[3rem] tw-w-auto tw-overflow-hidden text-nowrap tw-flex-col'>
          {content.map((cont) => (
            <div key={cont}>{cont}</div>
          ))}
        </div>
      )}
    </div>
  );
};

type EditTextboxRowProps = {
  name: string;
  object: fabric.Object;
  requestRender: () => void;
};

const EditTextboxRow = ({ name, object, requestRender }: EditTextboxRowProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${
        open ? '' : 'hover:tw-bg-[#F4F7FF] hover:tw-cursor-pointer'
      } tw-relative tw-grid tw-w-full tw-overflow-y-scroll tw-grid-cols-6 tw-gap-4  tw-p-4 tw-border-y-[1px] tw-duration-[250ms] tw-min-h-14 no-scrollbar`}
      onClick={() => {
        if (!open) setOpen(!open);
      }}
    >
      <div className='tw-col-span-2 tw-relative tw-w-[80px] tw-font-bold'>{name}:</div>
      <div className='tw-col-span-3 tw-h-6 tw-overflow-hidden'>
        {open ? '' : (object as fabric.Textbox).get('text')}
      </div>
      <i
        className={`${
          open ? 'tw-rotate-180 tw-rounded-full hover:tw-bg-[#F4F7FF] hover:tw-cursor-pointer' : ''
        } tw-duration-[250ms] bx bx-chevron-down tw-relative tw-flex tw-self-center tw-justify-self-end`}
        onClick={() => {
          if (open) setOpen(!open);
        }}
      />
      {canvasObjectProperties.map((property) => {
        return (
          <>
            <div className={`${open ? '' : 'tw-hidden'} tw-col-span-1`}></div>
            <div className={`${open ? '' : 'tw-hidden'} tw-col-span-2 tw-font-bold tw-self-center`}>
              {property.key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
                return str.toUpperCase();
              })}
              :
            </div>
            {property.inputType === 'select' ? (
              <Input
                id={`${name}${property.key.charAt(0).toUpperCase() + property.key.slice(1)}`}
                className={`${open ? '' : '!tw-hidden'} form-control tw-col-span-3`}
                name={property.key}
                defaultValue={((object as fabric.Textbox).get(property.key) as string) || ''}
                onChange={(e) => {
                  (object as fabric.Textbox).set(property.key, e.target.value);
                  requestRender();
                }}
                type={property.inputType}
                placeholder={`Enter ${property.key
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => {
                    return str.toUpperCase();
                  })} ...`}
                // invalid={
                //   validation.touched[(validation.values[param.key] as string) || ''] &&
                //   validation.errors[(validation.values[param.key] as string) || '']
                //     ? true
                //     : false
                // }
              >
                {property.selections &&
                  property.selections.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
              </Input>
            ) : (
              <Input
                id={`${name}${property.key.charAt(0).toUpperCase() + property.key.slice(1)}`}
                className={`${open ? '' : '!tw-hidden'} form-control tw-col-span-3 no-scrollbar`}
                name={property.key}
                defaultValue={((object as fabric.Textbox).get(property.key) as string) || ''}
                onChange={(e) => {
                  (object as fabric.Textbox).set(property.key, e.target.value);
                  requestRender();
                }}
                type={property.inputType}
                rows={property.inputType === 'text' ? 3 : undefined}
                placeholder={`Enter ${property.key
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => {
                    return str.toUpperCase();
                  })} ...`}
              />
            )}
            {/* <div className='tw-col-span-3'>{(object as fabric.Textbox).get(property.key)}</div> */}
          </>
        );
      })}
    </div>
  );
};

type ContainerButtonOptions = {
  copyButton: boolean;
  viewButton: boolean;
  downloadButton: boolean;
};

type CertificateContainerProps = {
  canvasId: string;
  templateBackground: string;
  certificateTemplate: CertificateTemplate;
  onSelect?: ReactEventHandler;
  displayCertificate: Certificate | null;
  onLoad?: (canvas: fabric.Canvas) => void;
  editMode: boolean;
  buttonOptions: ContainerButtonOptions;
  updateParam?: (certId: string, params: CertParameter[], email: string) => Promise<void>;
  tooltips?: boolean;
};

export const CertificateContainer = ({
  canvasId,
  templateBackground,
  certificateTemplate,
  displayCertificate,
  onLoad,
  editMode,
  buttonOptions,
  updateParam,
  tooltips,
}: CertificateContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef: MutableRefObject<fabric.Canvas | null> = useRef<fabric.Canvas | null>(null);
  const resizeObserverRef = useRef<ResizeObserver>(null);
  const [canvasTextboxes, setCanvasTextboxes] = useState<Array<fabric.Object>>([]);
  const [rendering, setRendering] = useState(false);

  const renderParam = async (
    params: CertParameter[],
    objects: (fabric.Textbox | fabric.FabricImage)[],
    canvasBackground: fabric.FabricImage
  ): Promise<void> => {
    try {
      const newCanvasTextboxes: Array<fabric.Object> = [];
      const newObjects = await Promise.all(
        params.map(async (param) => {
          if (param.paramType && param.paramType === 'image') {
            const imageObject = await imageFetcher(
              param.value ? param.value : param.key === 'signature' ? fakeSignature : fakeQR,
              {
                originX: param.originX,
                originY: param.originY,
                left: param.coeffX * (canvasBackground.width || 0),
                top: param.coeffY * (canvasBackground.height || 0),
                selectable: false,
                crossOrigin: 'anonymous',
              }
            );

            imageObject.scaleToWidth(param.width || 100);

            return imageObject;
          }

          const textbox = new fabric.Textbox(
            `${
              param.value && param.value !== 'ENG' && param.value !== 'VIE'
                ? param.value
                : `<${param.name}>`
            }`,
            {
              originX: param.originX || 'left',
              originY: param.originY || 'top',
              left: param.coeffX * (canvasBackground.width || 0),
              top: param.coeffY * (canvasBackground.height || 0),
              fontFamily: param.fontFamily || '',
              fontSize: param.fontSize || 42,
              width: param.width || 200,
              textAlign: param.textAlign || '',
              fontWeight: param.fontWeight || '',
              fill: param.fill || 'black',
              underline: param.underline || false,
              fontStyle: param.fontStyle || '',
              selectable: false,
              visible: !param.hidden,
            }
          );

          if (!ignoredParams.includes(param.key)) newCanvasTextboxes.push(textbox);
          return textbox;
        })
      );

      objects.push(...newObjects);
      console.log(objects);
      setCanvasTextboxes([...newCanvasTextboxes]);
    } catch (error: unknown) {
      toast.error('Something wrong happend while rendering. Please retry!');
      console.log(error);
    }
  };

  const renderCanvas = useCallback(
    async (container: HTMLDivElement | null, resizeObserver: ResizeObserver | null) => {
      setRendering(true);

      //fetching template's background
      const canvasBackground = await imageFetcher(templateBackground, { crossOrigin: 'anonymous' });

      const originalWidth = canvasBackground.width || 0;
      const originalHeight = canvasBackground.height || 0;
      const ratio = originalWidth / originalHeight;

      canvasRef.current = new fabric.Canvas(canvasId, {
        width: originalWidth,
        height: originalHeight,
        preserveObjectStacking: true,
        selection: false,
      });

      const objects: (fabric.Textbox | fabric.FabricImage)[] = [];

      if (!displayCertificate) {
        await renderParam(certificateTemplate.parameters, objects, canvasBackground);
      } else {
        await renderParam(
          getAllParams(displayCertificate, certificateTemplate),
          objects,
          canvasBackground
        );
      }

      // Debug
      canvasRef.current.on('object:modified', (e) => {
        console.log(e);
      });

      canvasRef.current?.add(...objects);

      canvasBackground.set({
        originX: 'left',
        originY: 'top',
      });

      /**
       * Render the canvas with the background image
       * - Render image
       * - Render canvas
       * - Invoke onLoad callback
       */
      if (canvasRef.current) {
        canvasRef.current.backgroundImage = canvasBackground;
        canvasRef.current.renderAll();
      }
      if (onLoad && canvasRef.current) {
        onLoad(canvasRef.current);
      }

      resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const { clientWidth, clientHeight } = entries[0].target;

        if (canvasRef.current && clientHeight) {
          const containerHeight = clientHeight;
          const containerWidth = clientWidth;

          if (containerWidth / containerHeight < ratio) {
            const scale = containerWidth / canvasRef.current.getWidth();
            const zoom = canvasRef.current.getZoom() * scale;
            canvasRef.current.setDimensions({
              width: containerWidth,
              height: containerWidth / ratio,
            });
            canvasRef.current?.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
          } else {
            const scale = containerHeight / canvasRef.current.getHeight();
            const zoom = canvasRef.current.getZoom() * scale;
            canvasRef.current.setDimensions({
              width: containerHeight * ratio,
              height: containerHeight,
            });
            canvasRef.current?.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
          }
        }
      });

      if (container && resizeObserver) {
        resizeObserver.observe(container);
      }

      setRendering(false);
    },
    [displayCertificate, certificateTemplate, templateBackground, canvasId, onLoad]
  );

  const requestUpdateParamaters = () => {
    if (updateParam && displayCertificate) {
      const updatedParameters = displayCertificate.parameters.map((param) => {
        if (ignoredParams.includes(param.key) || param.hidden) return { ...param };

        const correspondingTextbox = canvasTextboxes[
          displayCertificate.parameters
            .filter((parameter) => !ignoredParams.includes(parameter.key))
            .indexOf(param)
        ] as fabric.Textbox;

        console.log(correspondingTextbox);

        return {
          ...param,
          value: (correspondingTextbox.get('text') as string) || '',
          width: (correspondingTextbox.get('width') as number) || 1000,
          fontSize: (correspondingTextbox.get('fontSize') as number) || 50,
          fontFamily: (correspondingTextbox.get('fontFamily') as string) || '',
          fontStyle:
            (correspondingTextbox.get('fontStyle') as '' | 'normal' | 'italic' | 'oblique') ||
            'normal',
          fontWeight: (correspondingTextbox.get('fontWeight') as string) || 'normal',
          textAlign:
            (correspondingTextbox.get('textAlign') as
              | 'center'
              | 'left'
              | 'right'
              | 'justify'
              | 'justify-left'
              | 'justify-center'
              | 'justify-right'
              | undefined) || 'center',
          fill: (correspondingTextbox.get('fill') as string) || '#000000',
        };
      });

      const recipientEmail =
        displayCertificate.parameters.find((param) => {
          return param.key === 'email';
        })?.value || '';

      updateParam(displayCertificate._id, updatedParameters, recipientEmail);
    }
  };

  useEffect(() => {
    const container = containerRef?.current;
    const resizeObserver = resizeObserverRef?.current;

    renderCanvas(container, resizeObserver);

    return () => {
      if (container && resizeObserver) {
        resizeObserver.unobserve(container);
      }

      if (canvasRef.current) {
        canvasRef.current.dispose();
      }
    };
  }, [renderCanvas]);

  return (
    <>
      {tooltips && displayCertificate && (
        <div className='tw-absolute tw-flex tw-flex-row md:tw-flex-col tw-items-start tw-gap-2 tw-translate-y-[-3rem] tw-translate-x-[1rem] sm:tw-translate-x-[5rem] md:tw-translate-x-[9.5rem] lg:tw-translate-x-[-3.5rem] lg:tw-translate-y-[-1rem] tw-z-40 tw-animate-slideIn'>
          <Tooltip
            name='Detail information'
            icon='bx bx-info-circle'
            content={[
              `Date: ${new Date(displayCertificate.batch.createdAt).toLocaleDateString()}`,
              `Signee: ${displayCertificate.batch.signee || 'Unknown'}`,
            ]}
          />
          <Tooltip
            name='Share'
            icon='bx bx-share-alt'
            onClick={() => {
              try {
                if (displayCertificate)
                  navigator.clipboard.writeText(
                    `${window.location.origin}/chungnhan/${displayCertificate.certificateId}`
                  );
                toast.success('A link has been copied to clipboard');
              } catch (error: unknown) {
                handleAxiosError(error, (message) => toast.error(message));
              }
            }}
          />
          <Tooltip
            name='Download certificate'
            icon='bx bxs-download'
            onClick={() => {
              const canvas = canvasRef.current;
              if (canvas) {
                try {
                  canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
                  fabric.FabricImage.fromURL(
                    templateBackground,
                    { crossOrigin: 'anonymous' },
                    (img: fabric.FabricImage) => {
                      canvas.width = img.width || 0;
                      canvas.height = img.height || 0;
                      const dataURL = canvas.toDataURL({
                        format: 'jpeg',
                        quality: 1,
                        enableRetinaScaling: true,
                        multiplier: 1,
                      });
                      const link = document.createElement('a');
                      link.download = `${displayCertificate?.certificateId || 'certificate'}.jpeg`;
                      link.href = dataURL;
                      link.click();
                    }
                  );
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to download certificate');
                }
              }
            }}
          />
        </div>
      )}
      <div
        ref={containerRef}
        className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-w-[15rem] tw-w-full tw-h-[17.5rem] xs:tw-h-[20rem] lg:tw-h-[25rem] tw-aspect-square'
      >
        <canvas id={canvasId}></canvas>
      </div>
      {editMode && (
        <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-m-4 lg:tw-h-[27.5rem] lg:tw-min-w-[27.5rem] lg:tw-w-[27.5rem] tw-justify-between'>
          <div className='tw-relative tw-flex tw-flex-col tw-m-4 lg:tw-max-h-[25rem] tw-overflow-y-scroll no-scrollbar'>
            {displayCertificate &&
              canvasTextboxes.map((object) => {
                return (
                  <EditTextboxRow
                    key={canvasTextboxes.indexOf(object)}
                    name={
                      displayCertificate.parameters.filter(
                        (param) => !ignoredParams.includes(param.key)
                      )[canvasTextboxes.indexOf(object)].name
                    }
                    object={object}
                    requestRender={() => canvasRef.current?.requestRenderAll()}
                  />
                );
              })}
          </div>
          <Button
            type='button'
            color='primary'
            disabled={!displayCertificate || rendering}
            className='tw-self-end tw-mt-4'
            onClick={() => {
              console.log('saving');
              requestUpdateParamaters();
            }}
          >
            Save changes
          </Button>
        </div>
      )}
      <div>
        {buttonOptions.copyButton && (
          <Button
            type='button'
            color='primary'
            disabled={!displayCertificate}
            style={{ marginRight: '8px' }}
            onClick={() => {
              try {
                if (displayCertificate)
                  navigator.clipboard.writeText(
                    `${window.location.origin}/chungnhan/${displayCertificate.certificateId}`
                  );
                toast.success('A link has been copied to clipboard');
              } catch (error: unknown) {
                handleAxiosError(error, (message) => toast.error(message));
              }
            }}
          >
            Copy to clipboard
          </Button>
        )}
        {buttonOptions.viewButton && (
          <Button
            type='button'
            color='primary'
            disabled={!displayCertificate}
            style={{ marginRight: '8px' }}
            onClick={() => {
              if (displayCertificate)
                window?.open('/chungnhan/' + displayCertificate.certificateId, '_blank')?.focus();
              else toast.error('Can not view the certificate, please try again!');
            }}
          >
            View
          </Button>
        )}
        {buttonOptions.downloadButton && (
          <Button
            type='button'
            color='primary'
            onClick={() => {
              const canvas = canvasRef.current;
              if (canvas) {
                try {
                  canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
                  fabric.FabricImage.fromURL(
                    templateBackground,
                    { crossOrigin: 'anonymous' },
                    (img: fabric.FabricImage) => {
                      canvas.width = img.width || 0;
                      canvas.height = img.height || 0;
                      const dataURL = canvas.toDataURL({
                        format: 'jpeg',
                        quality: 1,
                        enableRetinaScaling: true,
                        multiplier: 1,
                      });
                      const link = document.createElement('a');
                      link.download = `${displayCertificate?.certificateId || 'certificate'}.jpeg`;
                      link.href = dataURL;
                      link.click();
                    }
                  );
                } catch (error) {
                  console.log(error);
                  toast.error('Failed to download certificate');
                }
              }
            }}
          >
            Download
          </Button>
        )}
      </div>
    </>
  );
};
