import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Container, Input, Table } from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { defaultEvent1 } from 'data/defaultEvent';
import { handleAxiosError } from 'helpers/handleError';
import { secondsToTimeDate } from 'helpers/timeConverter';
import useTitle from 'hooks/useTitle';
import EventCoreService from 'services/event-core.service';
import EventPeriodService from 'services/event-period.service';
import EventReportService from 'services/event-report.service';
import ParticipantService from 'services/participant.service';
import SocialDayService from 'services/social-day.service';
import { Event, EventPeriod, EventPermissions, Participant } from 'types';

import EventComposition from './EventComposition';
import { AddSocialDayModal } from './modal/AddSocialDayModal';
import { ExportReportModal } from './modal/ExportReportModal';

// import EventBarChart from './EventBarChart';

const limit = 10;

const EventObservations = () => {
  useTitle('Event statistics', {
    restoreOnUnmount: true,
  });
  const { id } = useParams();
  const [showExportModal, setShowReportModal] = useState(false);
  const [showSocialDaysModal, setShowSocialDaysModal] = useState(false);
  // Event
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<Event>(defaultEvent1);
  // Participants
  const [participantList, setParticipantList] = useState<Array<Participant>>([]);
  const [count, setCount] = useState(0);
  // SocialDays
  const [queryParticipantId, setQueryParticipantId] = useState<string | null>(null);
  const [periods, setPeriods] = useState<EventPeriod[] | null>(null);
  const [isSocialDaysAdded, setIsSocialDaysAdded] = useState(false);
  const [viewParticipant, setViewParticipant] = useState<string[]>([]);

  const [queryName, setQueryName] = useState('');
  const [queryEmail, setQueryEmail] = useState('');
  const debounceName = useDebounceCallback(setQueryName, 500);
  const debounceEmail = useDebounceCallback(setQueryEmail, 500);
  const [queryOrder, setQueryOrder] = useState<'asc' | 'desc'>('asc');
  const [attendedStatus, setAttendedStatus] = useState<boolean | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isFetching, setIsFetching] = useState(false);

  // Composition
  const [attendedCount, setAttendedCount] = useState<number>(0);
  const [roleComposition, setRoleComposition] = useState<
    { roleId: string; participantCount: number }[]
  >([]);

  // Permisson
  const [myPermissions, setMyPermissions] = useState<EventPermissions[]>([]);

  const navigate = useNavigate();

  const startTime = useMemo(() => secondsToTimeDate(event.startAt), [event]);
  const endTime = useMemo(() => secondsToTimeDate(event.endAt), [event]);

  const getRole = (roleId: string): string => {
    const result = event.roles.find((role) => role._id === roleId);

    return result?.title || 'Participant';
  };

  // const intToHex = (value: number) => {
  //   // const red = Math.floor(value % 256).toString(16);
  //   const green = Math.floor(value % 256).toString(16);
  //   const blue = Math.floor(value % 256).toString(16);

  //   const hex = `#ff${green.length > 1 ? green : green + '0'}${
  //     blue.length > 1 ? blue : blue + '0'
  //   }`;

  //   console.log(hex);

  //   return hex;
  // };

  const generateRandomHex = () => {
    return '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');
  };

  const searchParticipantName = () => {
    const input = document.getElementById('searchbarParticipantName') as HTMLInputElement;
    debounceName(input.value);
  };

  const searchParticipantEmail = () => {
    const input = document.getElementById('searchbarParticipantEmail') as HTMLInputElement;
    debounceEmail(input.value);
  };

  const getAttendedParticipantCount = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventReportService.getAttendedParticipantCount(id || '');
      const { payload } = data;
      setAttendedCount(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id]);

  const getRoleComposition = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventReportService.getParticipantCountOfRoles(id || '');
      const { payload } = data;
      setRoleComposition(payload);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id]);

  const getParticipants = useCallback(async () => {
    try {
      setLoading(true);
      const offset = limit * (currentPage - 1);
      const { data } = await ParticipantService.getParticipantsOfEvent(
        id || '',
        queryOrder,
        limit,
        offset,
        queryName,
        queryEmail,
        attendedStatus
      );
      const { payload } = data;
      const participantListWithSocialDays = payload.participantList.map((participant) => ({
        ...participant,
        socialDays: participant.socialDays ? participant.socialDays : 0,
      }));
      setViewParticipant([]);
      setParticipantList(participantListWithSocialDays);
      setCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id, currentPage, queryName, queryEmail, queryOrder, attendedStatus]);

  const getParticipantSocialDays = useCallback(async () => {
    try {
      if (event._id === 'defaultId1') return;
      if (!queryParticipantId) return;
      setIsFetching(true);
      const mySocialDay = await SocialDayService.getOneSocialDays(event._id, queryParticipantId);
      setParticipantList((prevParticipantList) =>
        prevParticipantList.map((participant) =>
          participant._id === queryParticipantId
            ? { ...participant, socialDays: mySocialDay.data.payload.totalSocialDays }
            : participant
        )
      );
      setIsFetching(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [event, queryParticipantId]);

  const getEventTimeline = useCallback(async () => {
    try {
      setLoading(true);
      if (!event) return;
      if (event._id === 'defaultId1') return;
      const { data } = await EventPeriodService.getEventTimeline(event._id);
      const { payload } = data;
      setPeriods(payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [event]);

  useEffect(() => {
    getEventTimeline();
  }, [getEventTimeline]);

  const getEvent = useCallback(async () => {
    try {
      setLoading(true);
      const mypermissions = await ParticipantService.getMyPermissions(id || '');
      const { data } = await EventCoreService.getEventById(id || '');
      const { payload } = data;
      setEvent(payload);
      setMyPermissions(mypermissions.data.payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  const getAllParticipantInfo = useCallback(async () => {
    try {
      console.log('fetching all participants');
      const { data } = await EventReportService.getAllParticipantInfo(id || '');
      const { payload } = data;

      return payload;
    } catch (error: unknown) {
      console.error('error:', error);
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  const getAllParticipantSocialDaysStatus = useCallback(async () => {
    try {
      const { data } = await SocialDayService.getParticipantSocialDayStatus(id || '');
      const { payload } = data;

      return payload;
    } catch (error: unknown) {
      console.error('error:', error);
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    getParticipants();
  }, [getParticipants]);

  useEffect(() => {
    getAttendedParticipantCount();
  }, [getAttendedParticipantCount]);

  useEffect(() => {
    getRoleComposition();
  }, [getRoleComposition]);

  useEffect(() => {
    if (isSocialDaysAdded || queryParticipantId) {
      getParticipantSocialDays(); // Fetch updated participants
      setIsSocialDaysAdded(false); // Reset the flag after fetching
    }
  }, [isSocialDaysAdded, getParticipantSocialDays, queryParticipantId]);
  if (loading)
    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumb title='Application' breadcrumbItem='Event observations' />
          </Container>
          <Col>
            <Card className='tw-relative tw-flex tw-flex-col'>
              <Loading height={320} width={320} />
            </Card>
          </Col>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <ExportReportModal
        show={showExportModal}
        setShow={setShowReportModal}
        onCloseClick={() => setShowReportModal(false)}
        title={event.title}
        getRole={getRole}
        isFetching={isFetching}
        setIsFetching={setIsFetching}
        fetchAllParticipants={getAllParticipantInfo}
        fetchAllSocialDaysStatus={getAllParticipantSocialDaysStatus}
      />
      {periods &&
        (myPermissions.includes(EventPermissions.MODIFY_SOCIAL_DAY) ||
          myPermissions.includes(EventPermissions.LEADER)) && (
          <AddSocialDayModal
            show={showSocialDaysModal}
            setShow={setShowSocialDaysModal}
            isLoading={isFetching}
            setIsLoading={setIsFetching}
            onCloseClick={() => setShowSocialDaysModal(false)}
            event={event}
            periods={periods}
            setIsAdded={() => setIsSocialDaysAdded(true)}
          />
        )}
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Event observations' backTo='/event' />
        </Container>
        <Col>
          <Card className='tw-relative tw-flex tw-flex-col'>
            <div className='tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-w-full tw-h-[15rem] md:tw-h-[10rem] tw-bg-[#3D4863] tw-rounded-t-lg tw-text-white'>
              <div className='tw-relative tw-flex tw-flex-col tw-gap-2'>
                <div className='tw-font-bold tw-relative tw-text-[1.25rem]'>{event.title}</div>
                <div className='tw-relative tw-max-w-[30rem] tw-max-h-[5.75rem] md:tw-max-h-[3rem] tw-overflow-x-scroll no-scrollbar tw-opacity-75'>
                  {event.description}
                </div>
                <div className='tw-flex tw-relative tw-flex-row tw-gap-2'>
                  <div className='tw-font-bold'>Attendance:</div>
                  <div>{attendedCount},</div>
                  <div className='tw-font-bold'>Total:</div>
                  <div>{count}</div>
                </div>
                <div className='tw-flex tw-relative tw-flex-row tw-gap-2'>
                  <div className='tw-font-bold'>From:</div>
                  <div>{startTime}</div>
                  <div className='tw-font-bold'>to:</div>
                  <div>{endTime}</div>
                </div>
              </div>
              <div
                onClick={() => navigate('/event/' + event._id)}
                className='tw-self-end tw-font-bold tw-cursor-pointer'
              >
                View detail
              </div>
            </div>
            <div className='tw-relative tw-flex tw-flex-col tw-w-full tw-gap-4'>
              <Card className='tw-relative tw-flex tw-shadow-none tw-flex-col tw-justify-between tw-p-4 xl:tw-col-span-2 xl:tw-col-start-4 xl:tw-row-span-2 xl:tw-row-start-1'>
                <div className='tw-relative tw-flex tw-flex-col tw-gap-4'>
                  <div className='tw-relative tw-flex tw-flex-rol tw-w-full tw-justify-between tw-items-center tw-font-bold'>
                    <div>Participants</div>
                    <div className='tw-flex tw-gap-3'>
                      {(myPermissions.includes(EventPermissions.MODIFY_SOCIAL_DAY) ||
                        myPermissions.includes(EventPermissions.LEADER)) && (
                        <Button
                          type='button'
                          color='primary'
                          disabled={loading}
                          onClick={() => setShowSocialDaysModal(true)}
                        >
                          Add Social Days
                        </Button>
                      )}
                      <Button
                        type='button'
                        color='primary'
                        disabled={loading}
                        onClick={() => setShowReportModal(true)}
                      >
                        Export Report
                      </Button>
                    </div>
                  </div>
                  <div className='tw-flex tw-flex-col tw-gap-y-4 tw-items-center tw-justify-center tw-w-full '>
                    <div className='tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-center tw-gap-4'>
                      <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                        <i className='bx bx-search-alt' />
                        <Input
                          className='!tw-border-0'
                          onKeyUp={searchParticipantName}
                          id='searchbarParticipantName'
                          type='text'
                          placeholder='Search by name...'
                        />
                      </div>
                      <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                        <i className='bx bx-search-alt' />
                        <Input
                          className='!tw-border-0'
                          onKeyUp={searchParticipantEmail}
                          id='searchbarParticipantEmail'
                          type='text'
                          placeholder='Search by email...'
                        />
                      </div>

                      {/* filter */}
                      <div className='tw-flex tw-flex-row tw-w-full md:tw-w-fit tw-justify-between xs:tw-justify-start tw-h-[2.5rem] tw-gap-4'>
                        <Input
                          id='orderSelect'
                          className='form-control !tw-w-full md:!tw-w-[8rem]'
                          name='orderSelect'
                          defaultValue='asc'
                          onChange={(e) => {
                            const order = e.target.value;
                            if (order === 'asc') setQueryOrder('asc');
                            else setQueryOrder('desc');
                          }}
                          type='select'
                          placeholder='Order'
                        >
                          <option key='asc' value='asc'>
                            Ascending
                          </option>
                          <option key='desc' value='desc'>
                            Descending
                          </option>
                        </Input>

                        <Input
                          id='attendedSelect'
                          className='form-control !tw-w-full md:!tw-w-[11rem]'
                          name='attendedSelect'
                          defaultValue={undefined}
                          onChange={(e) => {
                            const status = e.target.value;
                            if (status === 'all') setAttendedStatus(undefined);
                            else setAttendedStatus(status === 'true' ? true : false);
                          }}
                          type='select'
                          placeholder='Attendance status'
                        >
                          <option key='all' value={'all'}>
                            Attendance Status
                          </option>
                          <option key='true' value={'true'}>
                            Attended
                          </option>
                          <option key='false' value={'false'}>
                            Absent
                          </option>
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive tw-w-full'>
                    <Table className='project-list-table table-nowrap align-middle table-borderless'>
                      <thead className='tw-bg-[#3D4863] !tw-rounded-t-lg tw-text-white tw-font-bold'>
                        <tr>
                          <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                            #
                          </th>
                          <th className='!tw-bg-[#3D4863]' scope='col'>
                            Name
                          </th>
                          <th className='!tw-bg-[#3D4863]' scope='col'>
                            Email
                          </th>
                          <th className='!tw-bg-[#3D4863]' scope='col'>
                            Role
                          </th>

                          <th className='!tw-bg-[#3D4863]' scope='col'>
                            Attendance
                          </th>
                          <th className='!tw-bg-[#3D4863]' scope='col'>
                            Social Days
                          </th>
                        </tr>
                      </thead>
                      {loading ? (
                        <Loading width={320} height={320} />
                      ) : (
                        <tbody>
                          {participantList.map((participant: Participant, index) => (
                            <tr key={participant._id}>
                              <td className='text-truncate font-size-14 tw-font-bold'>
                                {limit * (currentPage - 1) + index + 1}
                              </td>
                              <td className='text-truncate font-size-14'>
                                {participant.user.name}
                              </td>
                              <td className='text-truncate font-size-14'>
                                {participant.user.email}
                              </td>
                              <td className='text-truncate font-size-14'>
                                {getRole(participant.role)}
                              </td>

                              <td
                                className={`${
                                  participant.attendedPeriods[0]
                                    ? 'tw-text-[#0F9D58]'
                                    : 'tw-text-[#A3ACC2]'
                                } text-truncate font-size-14 tw-font-bold`}
                              >
                                {participant.attendedPeriods[0] ? 'Arrived' : 'Absent'}
                              </td>
                              <td className='tw-text-center'>
                                {!viewParticipant.includes(participant._id) ? (
                                  <Button
                                    type='button'
                                    color='primary'
                                    size='md'
                                    onClick={() => {
                                      setQueryParticipantId(participant._id);
                                      setViewParticipant((prev) => [...prev, participant._id]);
                                    }}
                                  >
                                    View
                                  </Button>
                                ) : (
                                  <div>{participant.socialDays}</div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>
                  </div>
                </div>
                <div className='tw-relative tw-self-center tw-mt-4'>
                  <Pagination
                    count={count}
                    pageSize={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </Card>
              <EventComposition
                title='Composition'
                charts={[
                  {
                    title: 'Attendance',
                    dataValues: [attendedCount, count - attendedCount],
                    dataLabels: ['Attended', 'Absent'],
                    dataColors: ['#0B2878', '#A3ACC2'],
                  },
                  {
                    title: 'Roles',
                    dataValues: roleComposition.map((roleData) => {
                      return roleData.participantCount;
                    }),
                    dataLabels: roleComposition.map((roleData) => {
                      return getRole(roleData.roleId);
                    }),
                    dataColors: roleComposition.map(() => {
                      return generateRandomHex();
                    }),
                  },
                ]}
              />

              {/* <EventBarChart
                title='Time of arrival'
                charts={[
                  {
                    title: '',
                    dataValues: [4, 3, 3, 9, 8],
                    dataLabels: ['09:00', '09:15', '09:30', '09:45', '10:00'],
                    dataColors: ['#0B2878'],
                  },
                ]}
              /> */}
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventObservations;
