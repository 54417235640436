import { AxiosError } from 'axios';
import mime from 'mime';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { WorkBook, WorkSheet, read, utils, writeFile } from 'xlsx';

import Loading from 'Components/Common/LoadingIndicator';
import ManagerKeyModal from 'Components/Common/ManagerKeyModal';
import useTitle from 'hooks/useTitle';
import StudentService from 'services/student.service';
import { Join, PathsToStringProps, ResponseData, Student, StudentUpdateLog } from 'types';

import Breadcrumbs from '../../Components/Common/Breadcrumb';

type FlattenStudentKeys = Join<PathsToStringProps<Required<Student>>, '.'>;

type FlattenStudent = Record<FlattenStudentKeys, unknown>;

const translatedHeaders = [
  'Tên',
  'Họ',
  'Email',
  'Số điện thoại',
  'Mã số sinh viên',
  'Ngày sinh',
  'Giới tính',
  'Quê quán',
  'Dân tộc',
  'Địa chỉ',
  'Tôn giáo',
  'Nghề nghiệp',
  'Số CMND/CCCD',
  'Ngày cấp',
  'Nơi cấp',
  'Quốc tịch',
  'Trình độ Văn hóa',
  'Trình độ Chuyên môn',
  'Trình độ Lý luận chính trị',
  'Trình độ Tin học',
  'Trình độ Ngoại ngữ',
  'Mã định danh đoàn viên',
  'Ngày vào Đoàn',
  'Nơi vào Đoàn',
  'Số Nghị quyết chuẩn y kết nạp',
  'Số thẻ đoàn viên',
  'Đối tượng đoàn viên',
  'Rèn luyện đoàn viên',
  'Đánh giá, xếp loại đoàn viên',
  'Tình trạng đoàn viên',
  'Hội',
  'Đơn vị',
  'Ngày vào hội',
];

const sheetHeaderTemplate: FlattenStudentKeys[] = [
  'firstName',
  'familyName',
  'email',
  'phone',
  'studentId',
  'dob',
  'gender',
  'hometown',
  'ethnic',
  'address',
  'religion',
  'job',
  'citizenShip.citizenId',
  'citizenShip.citizenIdIssueDate',
  'citizenShip.citizenIdIssuePlace',
  'citizenShip.nation',
  'standards.academic',
  'standards.professional',
  'standards.politicalTheory',
  'standards.computer',
  'standards.foreignLanguage',
  'union.memberId',
  'union.issuedDate',
  'union.issuedPlace',
  'union.resolutionNumber',
  'union.cardNumber',
  'union.membership',
  'union.practice',
  'union.qualification',
  'union.status',
  'union.group',
  'union.unit',
  'party.partyIssueDate',
];

const BulkUpdate = () => {
  useTitle('Update student information', {
    restoreOnUnmount: true,
  });

  const [uploadError, setUploadError] = useState<string>('');
  const [status, setStatus] = useState<'unstarted' | 'pending' | 'fulfilled' | 'rejected'>(
    'unstarted'
  );
  const [file, setFile] = useState<File | null>(null);
  const [result, setResult] = useState<StudentUpdateLog | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [managerKey, setManagerKey] = useState<string>('');
  const [managerKeyModal, setManagerKeyModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const downloadSheetTemplate = () => {
    const rows = [];
    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Parameters');

    // .map((header) => {
    //   return header.charAt(0).toUpperCase() + header.slice(1);
    // });

    utils.sheet_add_aoa(worksheet, [translatedHeaders], { origin: 'A1' });
    utils.sheet_add_aoa(worksheet, [sheetHeaderTemplate], { origin: 'A2' });

    worksheet['!cols'] = [{ wch: 30 }];

    sheetHeaderTemplate.forEach(() => {
      if (worksheet['!cols']) worksheet['!cols'].push({ wch: 30 });
    });

    writeFile(workbook, 'Student template.xlsx', { compression: true });
  };

  const convertSheetToJSON = async (f: File): Promise<FlattenStudent[]> => {
    const fileBuffer: ArrayBuffer = await f.arrayBuffer();
    const workbook: WorkBook = read(fileBuffer, { type: 'buffer' });
    const { SheetNames } = workbook;
    const worksheet: WorkSheet = workbook.Sheets[SheetNames[0]];
    const studentData: FlattenStudent[] = utils.sheet_to_json<FlattenStudent>(worksheet, {
      header: sheetHeaderTemplate,
      range: 1,
      defval: undefined, // to avoid mongoose < 8 error
    });

    return studentData;
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const targetFile: File | undefined = event.target.files?.[0];
    if (targetFile) {
      if (targetFile.type === mime.getType('xlsx')) {
        setUploadError('');
        setFile(targetFile);
      } else {
        setUploadError('File format is incorrect');
      }
    } else {
      setUploadError('Please upload a file');
    }
  };

  const handleConvert = () => {
    if (file) {
      setStatus('pending');
      setManagerKeyModal(false);
      convertSheetToJSON(file)
        .then((studentData) => {
          return StudentService.addStudentList(studentData, managerKey);
        })
        .then((res) => {
          setStatus('fulfilled');
          setResult(res.data.payload);
        })
        .catch((error: AxiosError<ResponseData<unknown>, unknown>) => {
          setStatus('rejected');
          if (error.response?.data?.message) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage('Đã có lỗi xảy ra!');
          }
        });
    }
  };

  return (
    <React.Fragment>
      <ManagerKeyModal
        value={managerKey}
        onChange={(e) => setManagerKey(e.target.value)}
        show={managerKeyModal}
        onCloseClick={() => {
          setManagerKeyModal(false);
          setManagerKey('');
        }}
        onSubmit={handleConvert}
      />
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs
            title='Student information'
            breadcrumbItem='Update student information'
            backTo='/student-info'
          />

          <Row>
            <Col className='col-12'>
              <Card>
                <CardBody>
                  {status === 'unstarted' && (
                    <>
                      <h6 className='card-title'>Spreadsheet</h6>
                      <CardSubtitle className='mb-3'>
                        {' '}
                        The system only accepts Excel spreadsheet (.xlsx) following this{' '}
                        <button
                          style={{
                            background: 'none',
                            border: 'none',
                            textDecoration: 'underline',
                            color: '#0b2878',
                            padding: '0px',
                          }}
                          onClick={() => downloadSheetTemplate()}
                        >
                          format
                        </button>
                        .
                      </CardSubtitle>

                      <div className='mt-4'>
                        <div>
                          <Label htmlFor='formFileLg' className='form-label'>
                            Choose file
                          </Label>
                          <Input
                            className='form-control form-control-lg'
                            id='formFileLg'
                            type='file'
                            onChange={handleFileUpload}
                            invalid={uploadError !== ''}
                          />
                          {uploadError ? (
                            <FormFeedback type='invalid' className='font-size-12'>
                              {uploadError}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mt-3'>
                        <div className='mb-3'>
                          <Button
                            type='button'
                            color='primary'
                            className='w-md'
                            disabled={!file}
                            onClick={() => setManagerKeyModal(true)}
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                    </>
                  )}

                  {status === 'pending' && (
                    <div
                      style={{ height: 344 }}
                      className='d-flex flex-column justify-content-center align-items-center'
                    >
                      <div className='mb-3'>
                        <h5 className='font-weight-bold'>Processing</h5>
                      </div>

                      <div className='mb-4'>
                        <Loading width={200} height={200} />
                      </div>
                    </div>
                  )}

                  {status === 'fulfilled' && (
                    <div
                      style={{ height: 344 }}
                      className='d-flex flex-column justify-content-center align-items-center'
                    >
                      <div className='success-icon mb-3'>
                        <i className='bx bx-check font-size-48 text-white'></i>
                      </div>

                      <div className='mb-3'>
                        <h5 className='font-weight-bold'>Finished</h5>
                      </div>

                      <div className='mb-4'>
                        <p className='text-muted'>{result?.message}</p>
                      </div>

                      <div>
                        <Button
                          type='button'
                          color='primary'
                          className='w-md'
                          onClick={() => navigate('/student-info')}
                        >
                          Back
                        </Button>
                      </div>
                    </div>
                  )}

                  {status === 'rejected' && (
                    <Card>
                      <CardBody>
                        <div
                          style={{ height: 344 }}
                          className='d-flex flex-column justify-content-center align-items-center'
                        >
                          <div className='failed-icon mb-3'>
                            <i className='bx bx-x font-size-48 text-white'></i>
                          </div>

                          <div className='mb-3'>
                            <h5 className='font-weight-bold'>Failed</h5>
                          </div>

                          <div className='mb-4'>
                            <p className='text-muted'>{errorMessage}</p>
                          </div>

                          <div>
                            <Button
                              type='button'
                              color='primary'
                              className='w-md'
                              onClick={() => navigate('/student-info')}
                            >
                              Back
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BulkUpdate;
