import { EventPermissions } from 'types/core';

const EventPermissionUserFriendlyMap: { [key in EventPermissions]: string } = {
  [EventPermissions.LEADER]: 'Leader',
  [EventPermissions.MODIFY_TIMELINE]: 'Modify Timeline',
  [EventPermissions.MODIFY_DATA]: 'Modify Data',
  [EventPermissions.MODIFY_PARTICIPANT]: 'Modify Participant',
  [EventPermissions.MODIFY_SOCIAL_DAY]: 'Modify Social Day',
  [EventPermissions.GENERATE_REPORT]: 'Generate Report',
  [EventPermissions.CLOSE_EVENT]: 'Close Event',
  [EventPermissions.SUMBIT_FOR_VERIFICATION]: 'Submit for Verification',
  [EventPermissions.REGISTER_ATTENDANCE]: 'Register Attendance',
  [EventPermissions.DELETE_EVENT]: 'DELETE_EVENT',
};

export default EventPermissionUserFriendlyMap;
