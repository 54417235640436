import classnames from 'classnames';
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import { defaultEvent1 } from 'data/defaultEvent';
import { handleAxiosError } from 'helpers/handleError';
import useAppSelector from 'hooks/useAppSelector';
import EventCoreService from 'services/event-core.service';
import EventRoleService from 'services/event-role.service';
import ParticipantService from 'services/participant.service';
import { Event, EventPermissions, EventRole, Participant, Permission } from 'types/core';

import CreateRoleModal from './modal/CreateRoleModal';
import EditRoleModal from './modal/EditRoleModal';
import ThumbnailModal from './modal/ThumbnailModal';
import VerificationRequestModal from './modal/VerificationRequestModal';
enum Tab {
  BASIC_INFO = 'BASIC_INFO',
  TIMELINE = 'TIMELINE',
  PARTICIPANTS = 'PARTICIPANTS',
  ROLES = 'ROLES',
  HISTORY = 'HISTORY',
}
const EventBasicInformation = lazy(() => import('./EventBasicInformation'));
const EventRoles = lazy(() => import('./EventRoles'));
const EventTimeline = lazy(() => import('./EventTimeline'));
const EventParticipants = lazy(() => import('./EventParticipants'));
const EventHistory = lazy(() => import('./EventHistory'));
const tabs = [
  { key: Tab.BASIC_INFO, label: 'Information' },
  { key: Tab.TIMELINE, label: 'Timeline' },
  { key: Tab.PARTICIPANTS, label: 'Participants' },
  { key: Tab.ROLES, label: 'Roles' },
  { key: Tab.HISTORY, label: 'History' },
];

const EventDetail = () => {
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.Login);
  const [meParticipant, setMeParticipant] = useState<Participant | null>(null);
  const [event, setEvent] = useState<Event>(defaultEvent1);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<Tab>(Tab.BASIC_INFO);

  // Modals
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const [showThumbnailModal, setShowThumbnailModal] = useState(false);
  const [openEditRoleModal, setOpenEditRoleModal] = useState<EventRole | null>(null);
  const [openCreateRoleModal, setOpenCreateRoleModal] = useState(false);
  const [showAttendeesVerificationModal, setShowAttendeesVerificationModal] = useState(false);
  const [showDeleteAttendeesModal, setShowDeleteAttendeesModal] = useState(false);

  const [myPermissions, setMyPermissions] = useState<EventPermissions[]>([]);

  // Information
  const [croppedThumbnail, setCroppedThumbnail] = useState<Blob | null>(null);

  // Roles
  const [roles, setRoles] = useState<Array<EventRole>>([]);
  const [filteredRoles, setFilteredRoles] = useState<Array<EventRole>>([]);

  const searchRoles = () => {
    const input = document.getElementById('searchbarRole') as HTMLInputElement;
    const filteredList = roles.filter((role: EventRole) => {
      return role.title.toLowerCase().includes(input.value.toLowerCase());
    });
    setFilteredRoles(filteredList);
  };

  const updateThumbnail = async () => {
    try {
      if (!croppedThumbnail) {
        toast.error('There is nothing to update yet!');
        return;
      }

      const thumbnailBlob: Blob = croppedThumbnail;

      const thumbnailFile = new File([thumbnailBlob], `${event.title}Thumbnail.png`, {
        type: 'image/png',
      });

      console.log('thumbnail blob:', thumbnailBlob);
      console.log('cropped thumbnail blob:', croppedThumbnail);
      console.log('thumbnail file:', thumbnailFile);

      await EventCoreService.uploadPoster(event._id, thumbnailFile);

      await getEvent();
      setCroppedThumbnail(null);

      toast.success(`Event's poster has been updated!`);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
      setShowVerificationModal(false);
    }
  };

  const getUserParticipantId = useCallback(async () => {
    try {
      if (!id) return;
      setLoading(true);
      const myEvent = await ParticipantService.getMyEventById(id);
      setMeParticipant(myEvent.data.payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);
  useEffect(() => {
    getUserParticipantId();
  }, [getUserParticipantId]);

  const getEvent = useCallback(async () => {
    try {
      setLoading(true);
      const mypermissions = await ParticipantService.getMyPermissions(id || '');
      const { data } = await EventCoreService.getEventById(id || '');
      const { payload } = data;
      setMyPermissions(mypermissions.data.payload);
      setEvent(payload);

      // Roles
      setRoles(payload.roles);
      setFilteredRoles(payload.roles);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  // This is just a dummy function while necessary API is not ready
  const dummyVerificationFunction = (): Promise<void> => {
    const newPromise = new Promise<void>(() => {});

    return newPromise;
  };

  const getParticipantsByRole = async (roleId: string) => {
    try {
      const { data } = await EventRoleService.getParticipantsByRole(id || '', roleId);
      const { payload } = data;
      if (payload) return payload;
      return [];
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  };

  const createRole = async (role: {
    title: string;
    description: string;
    maxRegistration: number;
    permissions: Array<EventPermissions>;
  }) => {
    try {
      setLoading(true);

      if (roles.find((oldRole) => oldRole.title === role.title)) {
        toast.error('The title of this role already exists. Please use another title!');
        return;
      }

      // This is a temporary version without the use of get one event API so the id in this case may be undefined
      // Will be updated to set the event object instead once event core service suffices
      const { data } = await EventRoleService.createRole(id || '', role);
      const { payload } = data;
      setRoles(payload.roles);
      setFilteredRoles(payload.roles);
      setLoading(false);
      setOpenCreateRoleModal(false);
      toast.success(`A new role has been added`);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const updateRole = async (
    roleId: string,
    role: {
      _id: string;
      title: string;
      description: string;
      maxRegistration: number;
      permissions: Array<EventPermissions>;
    }
  ) => {
    try {
      setLoading(true);
      const participants = await getParticipantsByRole(roleId);
      if (participants && participants.length > role.maxRegistration) {
        toast.error('New max registration number is lower than current participants of this role');
        return;
      }

      // This is a temporary version without the use of get one event API so the id in this case may be undefined
      // Will be updated to set the event object instead once event core service suffices
      await EventRoleService.updateRole(id || '', role);
      await getEvent();
      setLoading(false);
      setOpenEditRoleModal(null);
      toast.success(`Role ${role.title} has been updated`);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  const deleteRole = async (role: EventRole) => {
    try {
      setLoading(true);
      // This is a temporary version without the use of get one event API so the id in this case may be undefined
      // Will be updated once event core service suffices
      const { data } = await EventRoleService.deleteRole(id || '', role._id);
      const { payload } = data;
      setRoles(payload.roles);
      setFilteredRoles(payload.roles);
      setLoading(false);
      toast.success(`Role ${role.title} has been deactivated`);
    } catch (error) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {user?.permissions?.includes(Permission.VERIFY_EVENT) && (
        <VerificationRequestModal
          show={showVerificationModal}
          onCloseClick={() => setShowVerificationModal(false)}
          onSendClick={() => dummyVerificationFunction()}
        />
      )}

      {(myPermissions?.includes(EventPermissions.LEADER) ||
        myPermissions?.includes(EventPermissions.MODIFY_DATA)) && (
        <ThumbnailModal
          defaultImage={event.poster?.preview}
          showThumbnailModal={showThumbnailModal}
          onCloseClick={() => setShowThumbnailModal(false)}
          setCroppedImageBlob={setCroppedThumbnail}
        />
      )}

      {myPermissions?.includes(EventPermissions.LEADER) && (
        <EditRoleModal
          role={openEditRoleModal}
          onCloseClick={() => setOpenEditRoleModal(null)}
          onUpdateClick={updateRole}
          onDeleteClick={deleteRole}
        />
      )}

      {myPermissions?.includes(EventPermissions.LEADER) && (
        <CreateRoleModal
          open={openCreateRoleModal}
          onCloseClick={() => setOpenCreateRoleModal(false)}
          onCreateClick={createRole}
        />
      )}

      {/* confirm send attendees verification request */}
      <Modal
        isOpen={showAttendeesVerificationModal}
        toggle={() => setShowAttendeesVerificationModal(!showAttendeesVerificationModal)}
        centered
        size='lg'
        contentClassName='tw-rounded-[0.5rem]'
      >
        <ModalBody>
          <div className='tw-flex tw-flex-col tw-gap-y-7 tw-items-start'>
            <span className='tw-font-bold tw-text-[#3D4863] tw-text-2xl'>
              Confirm and send verification request
            </span>

            <p>
              Once your verification has been sent, this event can not be modified or deleted until
              there is a response for it.
              <br></br>
              <br></br>
              Proceed to do this?
            </p>

            <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
              <Button
                type='button'
                color='primary'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#0B2878',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                  borderColor: '#0B2878',
                  backgroundColor: '#fff',
                }}
                onClick={() => setShowAttendeesVerificationModal(false)}
              >
                Cancel
              </Button>
              <Button
                type='button'
                color='primary'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#fff',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                }}
              >
                Confirm and send
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* confirm delete participant */}

      <Modal
        isOpen={showDeleteAttendeesModal}
        toggle={() => setShowDeleteAttendeesModal(!showDeleteAttendeesModal)}
        centered
        size='lg'
        contentClassName='tw-rounded-[0.5rem]'
      >
        <ModalBody>
          <div className='tw-flex tw-flex-col tw-gap-y-7 tw-items-start'>
            <span className='tw-font-bold tw-text-[#DA4437] tw-text-2xl'>Delete participant</span>

            <p>
              Once you delete this participant, this action can not be undone.
              <br></br>
              <br></br>
              Proceed to do this?
            </p>

            <div className='tw-flex tw-flex-row tw-w-full tw-justify-end tw-items-center tw-gap-x-4'>
              <Button
                type='button'
                color='danger'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#fff',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                }}
              >
                Delete
              </Button>
              <Button
                type='button'
                color='primary'
                size='sm'
                style={{
                  fontSize: '1rem',
                  color: '#3D4863',
                  fontWeight: 'bold',
                  borderRadius: '0.5rem',
                  borderColor: '#3D4863',
                  backgroundColor: '#fff',
                }}
                onClick={() => setShowDeleteAttendeesModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Event' breadcrumbItem='Create' backTo='/event' />
          <Row>
            <Col xs={12}>
              <Nav tabs role='tablist' className='nav-tabs-custom'>
                {tabs.map((tab) => (
                  <NavItem key={tab.key}>
                    <NavLink
                      className={classnames({
                        active: activeTab === tab.key,
                      })}
                      onClick={() => {
                        setActiveTab(tab.key);
                      }}
                    >
                      {tab.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <Card
                style={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                }}
              >
                <TabContent activeTab={activeTab}>
                  {/* Basic information section */}
                  <TabPane tabId={Tab.BASIC_INFO} id={Tab.BASIC_INFO}>
                    <Suspense
                      fallback={
                        <div>
                          <Loading width={320} height={320} />
                        </div>
                      }
                    >
                      {meParticipant && (
                        <EventBasicInformation
                          myPermissions={myPermissions}
                          event={event}
                          loading={loading}
                          thumbnail={
                            (croppedThumbnail && URL.createObjectURL(croppedThumbnail)) ||
                            event.poster?.preview
                          }
                          thumbnailUpdate={croppedThumbnail !== null}
                          setShowThumbnailModal={setShowThumbnailModal}
                          setShowVerificationModal={setShowVerificationModal}
                          updateThumbnail={updateThumbnail}
                          meParticipant={meParticipant}
                        />
                      )}
                    </Suspense>
                  </TabPane>
                  {/* Roles section */}
                  {myPermissions.includes(EventPermissions.LEADER) && activeTab === Tab.ROLES && (
                    <TabPane tabId={Tab.ROLES} id={Tab.ROLES}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventRoles
                          roles={filteredRoles}
                          loading={loading}
                          setOpenEditRoleModal={setOpenEditRoleModal}
                          setOpenCreateModal={() => setOpenCreateRoleModal(true)}
                          searchRoles={searchRoles}
                        />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* Timeline section */}
                  {activeTab === Tab.TIMELINE && (
                    <TabPane tabId={Tab.TIMELINE} id={Tab.TIMELINE}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventTimeline
                          eventStartTime={event.startAt}
                          eventEndTime={event.endAt}
                          loading={loading}
                        />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* Attendees section */}
                  {activeTab === Tab.PARTICIPANTS && (
                    <TabPane tabId={Tab.PARTICIPANTS} id={Tab.PARTICIPANTS}>
                      <Suspense
                        fallback={
                          <div>
                            <Loading width={320} height={320} />
                          </div>
                        }
                      >
                        <EventParticipants
                          event={event}
                          myPermissions={myPermissions}
                          roles={roles}
                          loading={loading}
                        />
                      </Suspense>
                    </TabPane>
                  )}
                  {/* History section */}
                  {activeTab === Tab.HISTORY && (
                    <TabPane tabId={Tab.HISTORY} id={Tab.HISTORY}>
                      {event._id !== 'defaultId1' && (
                        <Suspense
                          fallback={
                            <div>
                              <Loading width={320} height={320} />
                            </div>
                          }
                        >
                          <EventHistory eventId={event?._id} />
                        </Suspense>
                      )}
                    </TabPane>
                  )}
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EventDetail;
