import React, { useCallback, useState } from 'react';
import { Table, Button, Modal, ModalBody, Input } from 'reactstrap';

import SocialDayService from 'services/social-day.service';
import { Event, EventPeriod } from 'types';

type AddSocialDayModalProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  event: Event;
  onCloseClick: () => void;
  periods: EventPeriod[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setIsAdded: () => void;
};

export const AddSocialDayModal = ({
  show,
  setShow,
  event,
  onCloseClick,
  periods,
  isLoading,
  setIsLoading,
  setIsAdded,
}: AddSocialDayModalProps) => {
  const [socialDays, setSocialDays] = useState(
    periods.map((period) => ({
      ...period,
      socialDays: period.socialDays || 0, // Default socialDay to 0 if not provided
    }))
  );

  const handleChange = (index: number, value: string) => {
    // Convert to a valid number or default to 0
    const newValue = parseFloat(value) || 0;

    // Update socialDays with the new value at the correct index
    const updatedDays = [...socialDays];
    updatedDays[index].socialDays = newValue;

    setSocialDays(updatedDays);
  };

  const onSaveClick = useCallback(async () => {
    setIsLoading(true);
    try {
      for (const period of socialDays) {
        await SocialDayService.updateSocialDays(event._id, period._id, period.socialDays);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsAdded();
      setShow(false);
    }
  }, [setShow, socialDays, event._id, setIsLoading, setIsAdded]);

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      size='lg'
      scrollable={true}
      wrapClassName='tw-border-0'
    >
      <div className='modal-header'>
        <h5 className='modal-title mt-0 tw-text-primary tw-text-2xl'>
          {event.title}'s Social Days
        </h5>
        <button
          type='button'
          onClick={onCloseClick}
          className='btn-close position-absolute end-0 top-0 m-3'
        />
      </div>
      <div className='modal-content'>
        <ModalBody className='p-4'>
          <div className='tw-w-full tw-items-center tw-flex'>
            <div className='table-responsive tw-w-full'>
              <Table borderless responsive size='medium' hover>
                <thead className='tw-bg-[#3D4863] tw-text-white'>
                  <tr>
                    <th className='tw-text-left font-size-20' scope='col'>
                      Title
                    </th>
                    <th className='tw-text-center font-size-20' scope='col'>
                      Social Days
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {socialDays.map((period, index) => (
                    <tr key={index}>
                      <td className='font-size-20 tw-w-[75%] tw-py-3 tw-pr-3 tw-align-middle'>
                        {period.title}
                      </td>
                      <td>
                        <div>
                          <Input
                            id={`social-day-${index}`}
                            name={`social-day-${index}`}
                            type='number'
                            placeholder='Add social days...'
                            min='0'
                            step='0.5'
                            value={period.socialDays < 0.5 ? '' : period.socialDays.toString()}
                            onChange={(e) => handleChange(index, e.target.value)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className='tw-justify-center tw-mt-4 tw-flex tw-gap-4'>
            <Button
              type='button'
              color='primary'
              disabled={isLoading}
              size='lg'
              outline={true}
              onClick={onCloseClick}
            >
              Cancel
            </Button>
            <Button
              type='button'
              disabled={isLoading}
              color='primary'
              size='lg'
              onClick={() => {
                onSaveClick();
              }}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};
