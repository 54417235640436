import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, Col, Container, Input, Table } from 'reactstrap';
import { useDebounceCallback } from 'usehooks-ts';

import Breadcrumb from 'Components/Common/Breadcrumb';
import Loading from 'Components/Common/LoadingIndicator';
import Pagination from 'Components/Common/Pagination';
import { defaultEvent1 } from 'data/defaultEvent';
import { handleAxiosError } from 'helpers/handleError';
import { secondsToTimeDate } from 'helpers/timeConverter';
import EventCoreService from 'services/event-core.service';
import EventPeriodService from 'services/event-period.service';
import ParticipantService from 'services/participant.service';
import { Participant, Event, EventRole, EventPeriod } from 'types';

import { ReactComponent as QrIcon } from '../../assets/svg/qrIcon.svg';

import ScanModal from './modal/ScanModal';

const limit = 10;

const EventManagement = () => {
  const { id } = useParams();
  const [event, setEvent] = useState<Event>(defaultEvent1);
  const [eventPeriods, setEventPeriods] = useState<EventPeriod[]>([]);
  const [roles, setRoles] = useState<EventRole[]>([]);
  const [participantList, setParticipantList] = useState<Array<Participant>>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [queryName, setQueryName] = useState('');
  const [queryEmail, setQueryEmail] = useState('');
  const debounceName = useDebounceCallback(setQueryName, 500);
  const debounceEmail = useDebounceCallback(setQueryEmail, 500);
  const [queryOrder, setQueryOrder] = useState<'asc' | 'desc'>('asc');
  const [attendedStatus, setAttendedStatus] = useState<boolean | undefined>(undefined);
  const [openScanModal, setOpenScanModal] = useState(false);

  const [duration, setDuration] = useState(10);
  const [scanningPhase, setScanningPhase] = useState<'Scanning' | 'Processing' | 'Done'>(
    'Scanning'
  );

  const navigate = useNavigate();

  const startTime = useMemo(() => secondsToTimeDate(event.startAt), [event]);
  const endTime = useMemo(() => secondsToTimeDate(event.endAt), [event]);

  const getParticipantAttendanceStatus = (
    participant: Participant
  ): Array<'Absent' | 'Registered'> => {
    return eventPeriods.map((period) => {
      return participant.attendedPeriods.findIndex(
        (attendedPeriod) => attendedPeriod.period === period._id
      ) !== -1
        ? 'Registered'
        : 'Absent';
    });
  };

  const getRole = (roleId: string): string => {
    const result = roles.find((role) => role._id === roleId);

    return result?.title || 'Participant';
  };

  const searchParticipantName = () => {
    const input = document.getElementById('searchbarParticipantName') as HTMLInputElement;
    debounceName(input.value);
  };

  const searchParticipantEmail = () => {
    const input = document.getElementById('searchbarParticipantEmail') as HTMLInputElement;
    debounceEmail(input.value);
  };

  const currentTime = Date.now();

  const registerAttendance = async (periodId: string, participantId: string) => {
    try {
      setLoading(true);

      const { data } = await EventPeriodService.registerAttendance(
        id || '',
        periodId,
        participantId
      );
      const { payload } = data;
      await getParticipants();

      toast.success(`Participant ${payload.user.name} has registered successfully!`);
      setOpenScanModal(false);
      setScanningPhase('Scanning');
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  };
  const getParticipants = useCallback(async () => {
    try {
      setLoading(true);
      const offset = limit * (currentPage - 1);
      const { data } = await ParticipantService.getParticipantsOfEvent(
        id || '',
        queryOrder,
        limit,
        offset,
        queryName,
        queryEmail,
        attendedStatus
      );
      const { payload } = data;
      setParticipantList(payload.participantList);
      setCount(payload.count);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    } finally {
      setLoading(false);
    }
  }, [id, currentPage, queryName, queryEmail, queryOrder, attendedStatus]);

  const getEvent = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventCoreService.getEventById(id || '');
      const { payload } = data;
      setEvent(payload);
      // Roles
      setRoles(payload.roles);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  const getEventPeriods = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await EventPeriodService.getEventTimeline(id || '');
      const { payload } = data;
      console.log(payload);
      setEventPeriods(payload);
      setLoading(false);
    } catch (error: unknown) {
      handleAxiosError(error, (message) => toast.error(message));
    }
  }, [id]);

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  useEffect(() => {
    getParticipants();
  }, [getParticipants]);

  useEffect(() => {
    getEventPeriods();
  }, [getEventPeriods]);

  useEffect(() => {
    if (currentTime > defaultEvent1.startAt)
      setDuration(Math.floor((currentTime - defaultEvent1.startAt) / 1000));
    else if (currentTime < defaultEvent1.endAt)
      setDuration(Math.floor((defaultEvent1.endAt - currentTime) / 1000));
    else setDuration(0);
  }, [currentTime]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setDuration((prev) => prev - 1);
    }, 1000);
    // console.log(timerId);

    if (duration === 0) {
      clearInterval(timerId);
    }

    return function cleanup() {
      clearInterval(timerId);
    };
  }, [duration]);

  return (
    <React.Fragment>
      <ScanModal
        eventPeriods={eventPeriods}
        show={openScanModal}
        onCloseClick={() => {
          setOpenScanModal(false);
          setScanningPhase('Scanning');
        }}
        scanningPhase={scanningPhase}
        setScanningPhase={setScanningPhase}
        registerAttendance={registerAttendance}
      />

      <div className='page-content'>
        <Container fluid>
          <Breadcrumb title='Application' breadcrumbItem='Event Management' />
        </Container>
        <Col>
          <Card className='tw-relative tw-flex tw-flex-col'>
            <div className='tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-start md:tw-items-center tw-justify-between tw-px-6 tw-py-4 tw-w-full tw-h-[15rem] md:tw-h-[10rem] tw-bg-[#3D4863] tw-rounded-t-lg tw-text-white'>
              <div className='tw-relative tw-flex tw-flex-col tw-gap-2'>
                <div className='tw-font-bold tw-relative tw-text-[1.25rem]'>{event.title}</div>
                <div className='tw-relative tw-max-w-[30rem] tw-max-h-[5.75rem] md:tw-max-h-[3rem] tw-overflow-x-scroll no-scrollbar'>
                  {event.description}
                </div>
                <div className='tw-flex tw-relative tw-flex-row tw-gap-2'>
                  <div className='tw-font-bold'>From:</div>
                  <div>{startTime}</div>
                  <div className='tw-font-bold'>to:</div>
                  <div>{endTime}</div>
                </div>
              </div>
              <div className='tw-relative tw-h-full tw-w-full md:tw-w-auto tw-flex tw-flex-row md:tw-flex-col tw-justify-between tw-items-end'>
                <div
                  onClick={() => navigate('/event/' + event._id)}
                  className='tw-relative tw-font-bold tw-cursor-pointer'
                >
                  View detail
                </div>
                {/* <div className='tw-relative tw-text-[20px] tw-font-bold'>
                  {currentTime < event.endAt
                    ? `${currentTime > event.endAt ? 'ENDS AFTER' : 'STARTING IN'} ${Math.floor(
                        duration / 3600
                      )}:
                  ${Math.floor((duration % 3600) / 60)
                    .toString()
                    .padStart(2, '0')}
                  :${((duration % 3600) % 60).toString().padStart(2, '0')}`
                    : 'ENDED!'}{' '}
                </div> */}
              </div>
            </div>
            <div className='tw-relative tw-w-full tw-flex tw-flex-col tw-gap-4 tw-p-6'>
              <div className='tw-w-full tw-flex tw-flex-col yxl:tw-flex-row tw-justify-between tw-items-center tw-mb-3 tw-gap-4'>
                <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                  <i className='bx bx-search-alt' />
                  <Input
                    className='!tw-border-0'
                    onKeyUp={searchParticipantName}
                    id='searchbarParticipantName'
                    type='text'
                    placeholder='Search by name...'
                  />
                </div>
                <div className='tw-flex tw-w-full tw-flex-row tw-items-center tw-px-2 tw-relative tw-border-[1px] tw-border-solid tw-border-[#3D4863] tw-rounded-lg'>
                  <i className='bx bx-search-alt' />
                  <Input
                    className='!tw-border-0'
                    onKeyUp={searchParticipantEmail}
                    id='searchbarParticipantEmail'
                    type='text'
                    placeholder='Search by email...'
                  />
                </div>

                {/* filter */}
                <div className='tw-flex tw-flex-row tw-w-full sm:tw-w-fit tw-justify-between xs:tw-justify-start tw-h-[2.5rem] tw-gap-4'>
                  <Input
                    id='orderSelect'
                    className='form-control !tw-w-full xs:!tw-w-[8rem]'
                    name='orderSelect'
                    defaultValue='asc'
                    onChange={(e) => {
                      const order = e.target.value;
                      if (order === 'asc') setQueryOrder('asc');
                      else setQueryOrder('desc');
                    }}
                    type='select'
                    placeholder='Order'
                  >
                    <option key='asc' value='asc'>
                      Ascending
                    </option>
                    <option key='desc' value='desc'>
                      Descending
                    </option>
                  </Input>
                  <Input
                    id='attendedSelect'
                    className='form-control !tw-w-full sm:!tw-w-[11rem]'
                    name='attendedSelect'
                    defaultValue={undefined}
                    onChange={(e) => {
                      const status = e.target.value;
                      if (status === 'all') setAttendedStatus(undefined);
                      else setAttendedStatus(status === 'true' ? true : false);
                    }}
                    type='select'
                    placeholder='Attendance status'
                  >
                    <option key='all' value={'all'}>
                      Attendance Status
                    </option>
                    <option key='true' value={'true'}>
                      Attended
                    </option>
                    <option key='false' value={'false'}>
                      Absent
                    </option>
                  </Input>
                  <div
                    onClick={() => setOpenScanModal(true)}
                    className='tw-relative tw-items-center tw-justify-center tw-flex tw-h-[3rem] tw-aspect-square tw-border-solid tw-border-[1px] tw-border-[#3D4863] tw-rounded-lg tw-cursor-pointer'
                  >
                    <QrIcon className='tw-relative tw-w-[2rem] tw-h-[2rem] tw-fill-[#3D4863]' />
                  </div>
                </div>
              </div>

              <div className='table-responsive tw-w-full'>
                <Table className='project-list-table table-nowrap align-middle table-borderless'>
                  <thead className='tw-bg-[#3D4863] !tw-rounded-t-lg tw-text-white tw-font-bold'>
                    <tr>
                      <th className='!tw-bg-[#3D4863]' scope='col' style={{ width: '100px' }}>
                        #
                      </th>
                      <th className='!tw-bg-[#3D4863]' scope='col'>
                        Name
                      </th>
                      <th className='!tw-bg-[#3D4863]' scope='col'>
                        Email
                      </th>
                      <th className='!tw-bg-[#3D4863]' scope='col'>
                        Role
                      </th>

                      {eventPeriods.map((period) => {
                        return (
                          <th key={period._id} className='!tw-bg-[#3D4863]' scope='col'>
                            {period.title}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {loading ? (
                    <Loading width={320} height={320} />
                  ) : (
                    <tbody>
                      {participantList.map((participant: Participant, index) => (
                        <tr key={participant._id}>
                          <td className='text-truncate font-size-14 tw-font-bold'>{index + 1}</td>
                          <td className='text-truncate font-size-14'>{participant.user.name}</td>
                          <td className='text-truncate font-size-14'>{participant.user.email}</td>
                          <td className='text-truncate font-size-14'>
                            {getRole(participant.role)}
                          </td>

                          {getParticipantAttendanceStatus(participant).map(
                            (status, statusIndex) => {
                              return (
                                <td
                                  key={statusIndex}
                                  className={`${
                                    status === 'Registered'
                                      ? 'tw-text-[#0F9D58]'
                                      : 'tw-text-[#A3ACC2]'
                                  } text-truncate tw-font-bold font-size-14`}
                                >
                                  {status}
                                </td>
                              );
                            }
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </div>
              <div className='tw-relative tw-self-center tw-mt-4'>
                <Pagination
                  count={count}
                  pageSize={limit}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default EventManagement;
